import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const Logout = ({ modalVisible, setModalVisible, logout, stayLoggedIn, forcedLogout }) => {
    const handleClose = () => {
        if (!forcedLogout) {
            stayLoggedIn();
        }
        setModalVisible(false);
    };

    return (
        <Modal
            open={modalVisible}
            onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="logout-modal-title" variant="h6" component="h2">
                    {forcedLogout ? 'You have been logged out' : 'Are you there?'}
                </Typography>
                <Typography id="logout-modal-description" sx={{ mt: 2 }}>
                    {forcedLogout ? 'You have been logged out due to inactivity.' : 'logging you out soon for safety'}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    {forcedLogout ? (
                        <Button onClick={handleClose} variant="contained" color="primary">
                            Okay
                        </Button>
                    ) : (
                        <>
                            <Button onClick={handleClose} variant="contained" color="secondary" sx={{ mr: 2 }}>
                                Stay Logged In
                            </Button>
                            <Button onClick={()=>{handleClose(); logout()}} variant="contained" color="primary">
                                Logout
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default Logout;
