import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import HybridField from '../Inputs/HybridField';
import { TemplateContext, useTemplateContext } from "../../Providers/TemplateProvider";
import UseTemplateModel from "./Hooks/UseTemplateModel";
import { AuthContext } from "../../Providers/AuthProvider";
import {ThemeContext} from "styled-components";
import {Typography} from "@mui/material";

const TemplateModel = ({ id }) => {
    const { formData, setFormData, handleSubmit, isLoading, isError, mutation } = UseTemplateModel(id);
    const { ownerSub } = useContext(TemplateContext);
    const { authClaims } = useContext(AuthContext);
    const theme = useContext(ThemeContext)

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    if (isLoading) {
        return <Typography sx={{color:theme.colors.textAlt}}>Loading...</Typography>;
    }

    if (isError) {
        return <Typography sx={{color:theme.colors.textAlt}}>Error fetching templates, try refreshing</Typography>;
    }

    return (
        <form onSubmit={() => handleSubmit(formData)}>
            <HybridField sx={{color:theme.colors.textAlt}} initialText={formData.name} name="name" handleChange={handleInputChange}/>
            <HybridField sx={{color:theme.colors.textAlt}} initialText={formData.description} name="description" handleChange={handleInputChange}/>
            <HybridField sx={{color:theme.colors.textAlt}} type="number" initialText={formData.typical_cost} name="typical_cost" handleChange={handleInputChange}/>
            {authClaims.user_sub.toUpperCase() === ownerSub.toUpperCase() ? (
                <Button fullWidth type="submit" variant="contained" color="primary" disabled={isLoading}>
                    {mutation.isLoading ? "Saving..." : "Save"}
                </Button>
            ) : <div/>}
            {isError && <p>Error: Unable to update the template</p>}
        </form>
    );
};

export default TemplateModel;
