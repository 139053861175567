import React, {useContext} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {addTemplateParticipant, listTemplateParticipants} from "../../api/Participants";
import {AuthContext} from "../../Providers/AuthProvider";
import FormParticipants from "../Forms/FormParticipants";
import {CsrfContext} from "../../Providers/CsrfProvider";
import {TemplateContext, TemplateProvider} from "../../Providers/TemplateProvider";
import {Typography} from "@mui/material";
import {ThemeContext} from "styled-components";

const ParticipantsModel = ({ templateId }) => {
    const { authClaims } = useContext(AuthContext);
    const { csrfToken } = useContext(CsrfContext);
    const {ownerSub}=useContext(TemplateContext)

    // Query for initial data
    const { data: users, isLoading: isQueryLoading, isError: isQueryError } = useQuery(
        ['participants', templateId],
        listTemplateParticipants(csrfToken)
    );

    const theme = useContext(ThemeContext)

    // Mutation to manage data that updates
    const queryClient = useQueryClient();
    const mutation = useMutation(addTemplateParticipant(csrfToken), {
        onSuccess: () => {
            // Invalidate and refetch the 'users' query after adding a new user
            queryClient.invalidateQueries(['participants', templateId]);
        },
    });

    const handleAddUser = (formData) => {
        if (formData.email.trim() !== '') {
            formData.templateId=templateId;
            mutation.mutate(formData);
        }
    };

    if (isQueryLoading) {
        return <Typography sx={{color:theme.colors.textAlt}}>Loading...</Typography>;
    }

    if (isQueryError) {
        console.error('Error fetching users:', isQueryError);
        return <Typography sx={{color:theme.colors.textAlt}}>Error fetching participants, try refreshing</Typography>;
    }

    return (
        <>
            <FormParticipants templateId={templateId} participants={users} onSubmit={handleAddUser}/>
        </>
    );
};

export default ParticipantsModel;