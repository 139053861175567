import {useContext, useState} from 'react'
import axios from "axios";
import {AuthContext} from "../../../Providers/AuthProvider";
import ApiClient from "../../../api/ApiClient";
import {CsrfContext} from "../../../Providers/CsrfProvider";
import {useHistory} from "react-router-dom";
import NewUUID from "../../../Utils/Randy/NewUUID";

export const UseTemplateForm = (id) => {
    const history = useHistory();
    const {authClaims} = useContext(AuthContext)
    const {csrfToken} = useContext(CsrfContext)
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        typical_cost: 0,
    });

    const handleInputChange = (e) => {
        // Update the formData state when form fields change
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmitForm = (event) => {
        event.preventDefault()
        if (! allowSubmitForm()) {
            return
        }

        if (! id) {
            ApiClient.post('/api/bills/templates', {
                name: formData.name,
                description: formData.description,
                typical_cost: parseInt(formData.typical_cost)
            }, {
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    request_id: NewUUID(),
                    "X-CSRF-Token": csrfToken
                },
            }).then(response => {
                id = response.data.result.id
                history.push(`/bills/templates/${response.data.result.id}`)
            }).catch((err) => {
                console.error(err)
            })
        } else {
            ApiClient.put(`/api/bills/templates/${id}`, {
                name: formData.name,
                description: formData.description,
                typical_cost: parseInt(formData.typical_cost)
            }, {
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    request_id: NewUUID(),
                    "X-CSRF-Token": csrfToken
                },
            }).then(response => {
                id = response.data.result.id
                history.push(`/bills/templates/${response.data.result.id}`)
            }).catch((err) => {
                console.error(err)
            })
        }
    }

    const allowSubmitForm = () => {
        return true
    }

    return {
        handleInputChange,
        handleSubmitForm,
        allowSubmitForm,
    }
}
