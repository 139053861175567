import React, {useContext, useState} from 'react';
import {AuthContext} from "../../Providers/AuthProvider";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {addNewStatement, listTemplateStatements} from "../../api/Statements";
import SlidingForm from "../Forms/SlidingForm";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {TimelineOppositeContent} from "@mui/lab";
import FormatDate from "../../Utils/Formatters/FormatDate";
import {CsrfContext} from "../../Providers/CsrfProvider";
import {TemplateContext} from "../../Providers/TemplateProvider";
import Typography from "@mui/material/Typography";
import {ThemeContext} from "styled-components";

const StatementsModel = ({ templateId }) => {
    const {csrfToken} = useContext(CsrfContext)
    const { ownerSub } = useContext(TemplateContext);
    const { authClaims } = useContext(AuthContext);


    const [formData, setFormData] = useState({
        cost: 0,
    });
    const onChange = (e) => {
        const { name, value } = e.target;
        let parsedValue = value;
        // Check if the input type is number and convert the value
        if (e.target.type === 'number') {
            parsedValue = parseFloat(value);
            // Check if the parsedValue is NaN (Not a Number), then revert it to an empty string or zero
            if (isNaN(parsedValue)) {
                parsedValue = 0;
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: parsedValue,
        }));
    };

    // Mutation to manage data that updates
    const queryClient = useQueryClient();
    const mutation = useMutation(addNewStatement(csrfToken), {
        onSuccess: () => {
            // Invalidate and refetch the 'users' query after adding a new user
            queryClient.invalidateQueries(['statements', templateId]);
            queryClient.invalidateQueries(['transactions', templateId]);
        },
    });

    const onSubmit = () => {
        if (formData.cost > 0) {
            handleAddStatement(formData); // Combine existing and new users
            setFormData({ cost: 0}); // Reset the form data
        }
    };
    const theme = useContext(ThemeContext)

    const handleAddStatement = (formData) => {
        formData.templateId=templateId;
        mutation.mutate(formData);
    };

    // Query for initial data
    const { data: statements, isLoading: isQueryLoading, isError: isQueryError } = useQuery(
        ['statements', templateId],
        listTemplateStatements(csrfToken)
    );

    if (isQueryLoading) {
        return <Typography sx={{color:theme.colors.textAlt}}>Loading...</Typography>;
    }

    if (isQueryError) {
        console.error('Error fetching statements:', isQueryError);
        return <Typography sx={{color:theme.colors.textAlt}}>Error fetching statements, try refreshing</Typography>;
    }

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', width:'70%'}}>
                {authClaims.user_sub.toUpperCase() === ownerSub.toUpperCase()?
                <SlidingForm sx={{color:theme.colors.brandPrimary, backgroundColor:theme.colors.brandSecondary}} onChange={onChange} onSubmit={onSubmit} name="cost" placeholder="cost" type="number" text="New Statement"/>
                    :<div/>}
                <br/>
                <Timeline>
                {statements && statements.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                }).map((item, index) => (
                    <TimelineItem key={item.id}>
                        <TimelineOppositeContent color={theme.colors.textAlt}>
                            {FormatDate(item.created_at)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                        {/*todo: variant filled in once statement is paid up*/}
                            <TimelineDot variant={item.outstanding > 0 ?"outlined":""}/>
                            {index < statements.length-1 ? <TimelineConnector /> : ""}
                        </TimelineSeparator>
                        <TimelineContent color={theme.colors.textAlt}>{item.cost}</TimelineContent>
                    </TimelineItem>
                ))}
                </Timeline>
            </div>
        </>
    );
};

export default StatementsModel;