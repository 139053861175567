import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import Authorized from "../Components/Layouts/Authorized";
import { useParams } from 'react-router-dom'
import Box from "@mui/material/Box";
import TemplateModel from "../Components/Models/TemplateModel";
import ParticipantsModel from "../Components/Models/ParticipantsModel";
import {Grid} from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material';
import Typography from "@mui/material/Typography";
import StatementsModel from "../Components/Models/StatementsModel";
import TransactionsModel from "../Components/Models/TransactionsModel";
import {AuthContext} from "../Providers/AuthProvider";
import {CsrfContext} from "../Providers/CsrfProvider";
import LinkedAccountsModel from "../Components/Models/LinkedAccountsModel";
import {ThemeContext} from "styled-components";


const ProfileView = () => {
    const theme = useTheme();
    const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));
    const { authClaims } = useContext(AuthContext);
    const { csrfToken, } = useContext(CsrfContext);
    const cTheme = useContext(ThemeContext)
    let dividerBackground = cTheme.colors.brandThemedPrimary

    // Modified return statement to include onClick handlers
    const [headerSectionTitles, setHeaderSectionTitles] = useState([]);
    const [footerSectionTitles, setFooterSectionTitles] = useState([]);
    const scrollContainerRef = useRef(null);
    const sectionRefs = useRef([]);

    // Function to scroll to a section based on its title
    const scrollToSection = (title) => {
        const section = sectionRefs.current.find(
            (sec) => sec && sec.dataset.title === title
        );
        if (section && scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const topPos = section.offsetTop - container.offsetTop;
            container.scrollTo({
                top: topPos,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollContainer = scrollContainerRef.current;
            const containerScrollPosition = scrollContainer.scrollTop;
            const containerHeight = scrollContainer.offsetHeight;

            const visibleHeaderSections = [];
            const visibleFooterSections = [];

            sectionRefs.current.forEach((section) => {
                if (!section) return; // Guard clause for undefined sections

                const sectionTop = section.offsetTop - scrollContainer.offsetTop;
                const sectionBottom = sectionTop + section.offsetHeight;
                const title = section.dataset.title; // Fetch the title from the data-title attribute

                if (sectionTop <= containerScrollPosition + containerHeight && sectionBottom >= containerScrollPosition) {
                    if (sectionTop < containerScrollPosition) {
                        visibleHeaderSections.push(title);
                    } else if (sectionBottom > containerScrollPosition + containerHeight) {
                        visibleFooterSections.push(title);
                    }
                }
            });

            setHeaderSectionTitles(visibleHeaderSections);
            setFooterSectionTitles(visibleFooterSections);
        };

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []); // Note: If your sections can change, consider adding dependencies here


    const assignRef = (section, index) => {
        sectionRefs.current[index] = section;
    };

    // Modified return statement to include onClick handlers
    return (
        <>
            <Authorized>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100vw',
                        position:'fixed',
                        overflow:'hidden',
                        backgroundColor:cTheme.colors.brandPrimary,
                    }}
                >
                    <Box
                        sx={{
                            padding: '20px',
                            maxWidth: '800px',
                            // width: '100%',
                            margin: 'auto',
                            borderRadius: '8px',
                            height: '85vh', width: '90vw', position: 'fixed', bottom: '0', left:'50%', transform:'translateX(-50%)'
                        }}
                    >
                        <div ref={scrollContainerRef} style={{overflow: 'auto', height: '85vh'}}>
                            <div style={{position: 'sticky', top: 0, padding: '10px', zIndex: 100}}>
                                {headerSectionTitles.map((title, index) => (
                                    <div key={`header-${index}`} onClick={() => scrollToSection(title)}
                                         style={{cursor: 'pointer'}}>
                                        {title}
                                    </div>
                                ))}
                            </div>
                            <div
                                key="section-transactions"
                                ref={(el) => assignRef(el, 1)}
                                data-title={"Transactions"}
                                className="section">
                                <br/>
                                <Typography variant="h6" color="secondary">Financial Accounts</Typography>
                                <Box
                                    sx={{
                                        background: cTheme.colors.brandSecondary,
                                        borderTop: '1px solid #ccc',
                                        height: '3px',
                                        width: '100%', // Set width to fill the entire cell
                                        margin: '20px 0',
                                    }}
                                />
                                <br/>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <LinkedAccountsModel/>
                                </div>
                            </div>
                            <div style={{
                                position: 'sticky',
                                bottom: 0,
                                padding: '10px',
                                zIndex: 100
                            }}>
                                {footerSectionTitles.map((title, index) => (
                                    <div key={`footer-${index}`} onClick={() => scrollToSection(title)}
                                         style={{cursor: 'pointer'}}>
                                        {title}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Box>
                </Box>
            </Authorized>
        </>
    );
};


export default ProfileView;