import React from 'react';
import FormTemplate from "../Components/Forms/FormTemplate";
import Authorized from "../Components/Layouts/Authorized";
import { useParams } from 'react-router-dom'


const ViewNewTemplate = React.memo(() => {
    return(
        <>
            <Authorized>
             <FormTemplate/>
            </Authorized>
        </>
    )
});

export default ViewNewTemplate;
