import React, { useState } from 'react';
import {UseHybridField} from "./Hooks/UseHybridField";
import {Input} from "@mui/base";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const HybridField = ({ initialText, isEditable, handleChange, sx, ...props }) => {
    const [text, setText] = useState(initialText);
    const { focused, handleFocus, handleBlur } = UseHybridField();

    const handleInputChange = (e) => {
        setText(e.target.value);
    };

    const handleInputBlur = (e) => {
        handleBlur()
        handleChange(e);
    }

    return (
        <>
            {isEditable ? (
                <TextField
                    value={text}
                    onFocus={handleFocus}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    style={{ opacity: focused ? 1 : 0.5 }}
                    margin="normal"
                    variant="outlined"
                    size={'small'}
                    sx={{...sx}}
                    {...props}
                />
            ) : (
                <div>
                <Typography
                    margin="normal"
                    variant="h5"
                    sx={{...sx}}
                >{text}</Typography>
                <br/></div>
            )}
        </>
    );
};

export default HybridField;
