// UserForm component
import React, {useContext, useState} from "react";
import UseHybridForm from "./Hooks/UseHybridForm";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputValidatorField from "../Inputs/InputValidatorField";
import {IsValidEmail, IsValidText} from "../../Utils/Validators";
import IconButton from "@mui/material/IconButton";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {addTemplateParticipant, inviteTemplateParticipant} from "../../api/Participants";
import {AuthContext} from "../../Providers/AuthProvider";
import {CsrfContext} from "../../Providers/CsrfProvider";
import {TemplateContext} from "../../Providers/TemplateProvider";
import {Typography} from "@mui/material";
import {ThemeContext} from "styled-components";
import {PaperPlaneTilt, User, UserPlus} from "@phosphor-icons/react";

const ParticipantItem = ({templateId, user}) => {
    const {csrfToken} = useContext(CsrfContext)
    const { authClaims } = useContext(AuthContext);
    const { ownerSub } = useContext(TemplateContext);
    const [inviteSent, setInviteSent] = useState(false);

    const handleSendInvite = async () => {
        // Example API call to send invite
        const formData = {
            templateId: templateId, // Example template ID
            email: user.email // Participant's email
        };

// Now, call the function
        const invite = inviteTemplateParticipant(csrfToken);

// Use the function
        const executeInvite = async () => {
            try {
                const result = await invite(formData);
            } catch (error) {
                console.error('Failed to invite participant:', error);
            }
        };

    // Execute the function at the appropriate place in your code
        await executeInvite();
    };

    const theme = useContext(ThemeContext)

    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '10px' }}>
            <Typography sx={{color:theme.colors.textAlt}} style={{ marginRight: '10px' }}>{user.email}</Typography>
            {authClaims.user_sub.toUpperCase() === ownerSub.toUpperCase()?
            <IconButton onClick={handleSendInvite} color="primary">
                {inviteSent ? <User size='1.2rem' color={theme.colors.textAlt} /> : <PaperPlaneTilt size='1.2rem' color={theme.colors.textAlt}/> }
            </IconButton>
                :<div/>}
            {/*<IconButton onClick={handleSendInvite} color="primary">*/}
            {/*    <PersonOffIcon/>*/}
            {/*</IconButton>*/}
        </div>
    );
}

const FormParticipants = ({ onSubmit, participants, templateId, ...props }) => {
    const { ownerSub } = useContext(TemplateContext);
    const { authClaims } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        email: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (formData.email.trim() !== '') {
            onSubmit(formData); // Combine existing and new users
            setFormData({ email: ''}); // Reset the form data
        }
    };
    const { canEdit, toggleFormMode } = UseHybridForm();
    const theme = useContext(ThemeContext)

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    ...props,
                }}
            >
                {participants && Array.isArray(participants) ? participants.map(user => (
                    <ParticipantItem key={user.id} templateId={templateId} user={user}/>
                )) : <Typography variant="body1">no one's here, let's fix that</Typography>}

                {authClaims.user_sub.toUpperCase()===ownerSub.toUpperCase()?
                <Box sx={{display: 'flex', alignItems: 'center', margin: '10px'}}>
                <InputValidatorField name="email"
                                     label="Email"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidEmail}
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                                     sx={{flex:1}}
                />
                    <IconButton  color="primary">
                        <UserPlus size='2rem' color={theme.colors.textAlt} onClick={handleSubmit}/>
                    </IconButton>
                    {/*<IconButton  color="primary">*/}
                    {/*    <PersonAddDisabledIcon/>*/}
                    {/*</IconButton>*/}
                </Box>
                    :<div/>}
                {/*<Button type="submit" variant="contained" color="primary" fullWidth sx={{mt: 2}}> Add </Button>*/}
            </Box>
        </>
    );
};

export default FormParticipants;