import Button from '@mui/material/Button';
import React, {useContext} from 'react'
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {UseSignupForm} from "./Hooks/UseSignupForm";
import InputValidatorField from "../Inputs/InputValidatorField";
import {Tooltip} from "react-tooltip";
import {IsValidEmail, IsValidNumber, IsValidPassword, IsValidText} from "../../Utils/Validators";
import {ThemeContext} from "styled-components";

const FormSignup = React.memo(() => {
    const {
        handleInputChange,
        handleSubmitForm,
        allowSubmitForm,
    } = UseSignupForm();

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmitForm}
                sx={{
                    // position: 'absolute',
                    // left: '50%',
                    // top: '50%',
                    // transform: 'translate(-50%, -50%)',
                    flex: 1,
                    // maxWidth: '500px',
                    margin: 'auto',
                    padding: '20px',
                    borderRadius: '8px',
                    // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                }}
            >
                {/*<Typography variant="h5" component="div" sx={{mb: 2}}>*/}
                {/*    Sign Up*/}
                {/*</Typography>*/}
                {/*<br/>*/}
                <InputValidatorField helperText="(Required)"
                                     label="Display Name"
                                     type="name"
                                     name="name"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidText}
                                     autoComplete="first-name"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                                     requirementsText="Required - what do friends call you"
                />
                <br/>
                <br/>
                <InputValidatorField helperText="(Required)"
                                     label="Email"
                                     type="email"
                                     name="email"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidEmail}
                                     autoComplete="email"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                                     requirementsText="Required - we need a valid email to proceed"
                />
                <br/>
                <br/>
                <InputValidatorField helperText="(Required, len >= 12)"
                                     label="Password"
                                     type="password"
                                     name="password"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidPassword}
                                     autoComplete="current-password"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                                     requirementsText={["",
                                         <div>
                                             Required - password must include:
                                             <br/>* 12 - 36 Characters
                                             <br/>* Uppercase & Lowercase letters
                                             <br/>* Numbers
                                             <br/>* One special character: ^$*.[]{}()?-"!@#%&/\,':;|_~`+=
                                         </div>
                                         , ""]}
                />
                <br/>
                <br/>
                <Button type="submit" variant="contained" color="secondary" fullWidth
                        sx={{mt: 2, color: "primary", fontWeight: "bold", fontSize: "1.05em"}}>
                    Join </Button>
                <br/>
            </Box>
        </>
    );
});

export default FormSignup;