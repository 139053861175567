import React from 'react';

const IconAppMenu = ({ color = 'currentColor' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" style={{ color }}>
        <rect width="256" height="256" fill="none"/>
        <circle cx="128" cy="128" r="24" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="16"/>
        <circle cx="48" cy="128" r="24" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="16"/>
        <circle cx="208" cy="128" r="24" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="16"/>
    </svg>
);

export default IconAppMenu;