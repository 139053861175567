const darkTheme = {
    current: "dark",
    colors: {
        text: 'black',
        textAlt: 'white',
        textThemed: 'white',
        textAltThemed: 'black',

        background: '#13152A',
        brandSubmit: '#42E196',
        brandThemedPrimary: '#68ADB7',
        brandThemedSecondary: '#212447',
        brandPrimary: '#212447',
        brandPrimaryHover:'#21D480',
        brandSecondary: '#68ADB7',
        brandSecondaryHover:'#00C8F5',
        brandTertiary: '#3DD4AD',
        brandTertiaryHover:'#2CC9A2',
        // Add more colors as needed
    },
    fonts: {
        primary: 'League Spartan',
    },
    spacing: {
        small: '8px',
        medium: '16px',
        large: '24px',
        // Add more spacing sizes as necessary
    },
    // Add any other lightTheme properties you need
};

export default darkTheme;