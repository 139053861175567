import axios from "axios";
import ApiClient from "./ApiClient";
import NewUUID from "../Utils/Randy/NewUUID";

export const listTemplateStatements = (csrfToken) => {
    return async ({queryKey}) => {
        const [_, templateId] = queryKey
        const response = await ApiClient.get(`/api/bills/template/${templateId.toString()}/statements`, {
            headers: {
                accept: "text/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        });
        return response.data.result
    }
}

export const addNewStatement = (csrfToken) => {
    return async (formData) => {
        try {
            const response = await ApiClient.post(
                `/api/bills/template/${formData.templateId}/statements`,
                {
                    cost: formData.cost,
                },
                {
                    headers: {
                        accept: 'application/json',
                        request_id: NewUUID(),
                        "X-CSRF-Token": csrfToken
                    },
                }
            );

            return response.data.result;
        } catch (error) {
            console.error('Error adding template statement:', error);
            throw error; // Re-throw the error to propagate it to the mutation
        }
    };
};