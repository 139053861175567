// import React from 'react'
//
// export default function HeroShoppingPhoto({width='100vw'}) {
//     return (
// 		<>
// 		<img src="/hero_shopping.png" alt="Description of the image" style={{ width: `${width}` }}/>
// 		</>
// 	)
// }

import React from 'react';

export default function HeroShoppingPhoto({ width = '100%' }) {
	return (
		<div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
			<img
				src="/hero_shopping.png"
				alt="Description of the image"
				style={{ width: '100%', height: '100%', objectFit: 'cover' }}
			/>
		</div>
	);
}
