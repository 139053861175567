import React, { useContext, useRef, useState } from 'react';
import {Box, Typography, Button, useMediaQuery, useTheme, Paper} from '@mui/material';
import Unauthorized from "../Components/Layouts/Unauthorized";
import LogoDrkLrg from "../Images/Logo/LogoDrkLrg";
import { ThemeContext } from "styled-components";
import InputValidatorField from "../Components/Inputs/InputValidatorField";
import { IsValidEmail } from "../Utils/Validators";
import LandingPagePhoto from "../Images/Logo/LandingPagePhoto";
import ApiClient from "../api/ApiClient";
import NewUUID from "../Utils/Randy/NewUUID";
import { CsrfContext } from "../Providers/CsrfProvider";
import NewPassword from "../Utils/Randy/NewPassword";
import LogoLgtHori from "../Images/Logo/LogoLgtHori";
import LogoDrkHori from "../Images/Logo/LogoDrkHori";
import HeroShoppingPhoto from "../Images/Logo/HeroShoppingPhoto";
import HeroUtilitiesPhoto from "../Images/Logo/HeroUtilitiesPhoto";
import HeroVacationPhoto from "../Images/Logo/HeroVacationPhoto";

const ViewLanding = () => {
    const theme = useTheme();
    const cTheme = useContext(ThemeContext);
    const { csrfToken } = useContext(CsrfContext);

    const billRef = useRef(null);
    const budgetRef = useRef(null);
    const saveRef = useRef(null);

    const scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    };

    const useStyles = {
        section: {
            width: '100vw',
            height: '100vh',
            boxSizing: 'border-box',
        },
        primary: {
            flex: '1 0 60%',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            minWidth: '35vw',
            '@media (max-width: 768px)': {
                flexBasis: '100%',
                minWidth: '100%',
            },
        },
        secondary: {
            flex: '1 0 40%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            boxSizing: 'border-box',
            minWidth: '35vw',
            '@media (max-width: 768px)': {
                flexBasis: '100%',
                minWidth: '100%',
            },
        },
        thirdsGrid: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            gap: '2vw',
            width: '100%',
            padding: '2vw',
            boxSizing: 'border-box',
            backgroundColor: cTheme.colors.brandPrimary,
            '@media (max-width: 768px)': {
                // flexDirection: 'column',
                gap: '10px',
            },
        },
        thirdsGridItem: {
            flex: '1',
            border: '1px solid rgba(0,0,0,0.1)',
            borderRadius: '12px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '20px',
            boxSizing: 'border-box',
            backgroundColor: cTheme.colors.background,
            boxShadow: 'inset 5px 5px 5px rgba(0, 0, 0, .2)',
        },
        cards: {
            display: 'flex',
            padding: '5px',
        },
        cardItem: {
            flex: '3',
            border: '1px solid rgba(0,0,0,0.1)',
            borderRadius: '12px',
            // alignItems: 'flex-start',
            // justifyContent: 'flex-start',
            padding: '20px',
            boxSizing: 'border-box',
            backgroundColor: cTheme.colors.brandPrimary,
            color: cTheme.colors.textAlt,
            boxShadow: '24',
            textAlign: 'left',
            alignItems: 'center',
            justifyContent: 'center'
        },
        cardVisual: {
            flex: '2',
        },
        header: {
            width: '100%',
            textAlign: 'center',
            cursor: 'pointer',
        },
        bodyText: {
            textAlign: 'left',
            width:'70%'
        },
        appBarSpacer: {
            minHeight: '64px',
        },
    };

    const [formData, setFormData] = useState({
        email: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmitForm = (event) => {
        event.preventDefault();
        ApiClient.post('/api/auth/signup', {
            name: "early adopter",
            email: formData.email,
            password: NewPassword(20),
        }, {
            headers: {
                accept: "application/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken.toString(),
            },
        }).then(() => {
            setFormData((prevData) => ({
                ...prevData,
                ['email']: '',
            }));
            alert("You're an early adopter! But hold tight, we are working out the kinks. Watch for an email in the next few weeks about how to move forward")
        }).catch((err) => {
            console.error(err);
        });
    };

    // Define breakpoints
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    let bodyTextStyles = {
        fontSize:'1.25rem',
        textAlign: 'left',
        width:'70%'
    };

    if (isSmallScreen) {
        bodyTextStyles = { ...bodyTextStyles, fontSize:'1rem', width: '100%', padding: '10px' };
    } else if (isMediumScreen) {
        bodyTextStyles = { ...bodyTextStyles, fontSize:'1.10rem', width: '80%', padding: '20px' };
    } else if (isLargeScreen) {
        bodyTextStyles = { ...bodyTextStyles, fontSize:'1.25rem', width: '60%', padding: '30px' };
    }

    let boxStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    if (isSmallScreen) {
        boxStyles = { ...boxStyles, width: '100%', height: '45vh', padding: '10px' };
    } else if (isMediumScreen) {
        boxStyles = { ...boxStyles, width: '80%', height: '65vh', padding: '20px' };
    } else if (isLargeScreen) {
        boxStyles = { ...boxStyles, width: '60%', height: '80vh', padding: '30px' };
    }

    let logoSize = '100vw'
    if (isSmallScreen) {
        logoSize = '65vw'
    } else if (isMediumScreen) {
        logoSize = '55vw'
    } else if (isLargeScreen) {
        logoSize = '28vw'
    }

    return (
        <Unauthorized>
            <Box sx={{height: "55px", backgroundColor: cTheme.colors.brandPrimary }}/> {/* IMPORTANT!! Header spacer */}
            <Paper sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: cTheme.colors.brandPrimary
            }}>
                <Box sx={{display: 'flex', flexWrap: 'wrap', flex: 1}}>
                    <Box sx={boxStyles}>
                        <LogoDrkHori width={logoSize}/>
                    </Box>
                    <Box sx={{
                        ...useStyles.secondary,
                        backgroundColor: cTheme.colors.brandPrimary,
                        color: cTheme.colors.textAlt
                    }}>
                        <Box sx={{display: "flex", flexDirection: "column"}}>
                            <InputValidatorField
                                label="Email"
                                type="email"
                                name="email"
                                handleChange={handleChange}
                                isValidInput={IsValidEmail}
                                initialValue=""
                                autoComplete="email"
                                InputLabelProps={{
                                    style: {color: 'white'},
                                }}
                                InputProps={{
                                    style: {color: 'white'},
                                }}
                            />

                            <Button
                                onClick={handleSubmitForm}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    mt: 2, alignSelf: 'center',
                                    borderColor: 'white',
                                    color: cTheme.colors.textAlt,
                                    '&:hover': {
                                        borderColor: 'lightgray',
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    },
                                }}
                            >
                                Request Early Access
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <br/>
                <br/>
                <Paper sx={useStyles.thirdsGrid}>
                    <Paper sx={{
                        padding: 2,
                        margin: '20px 0',
                        borderRadius: 2,
                        borderStyle: 'solid',
                        background: cTheme.colors.brandSecondary,
                        color: cTheme.colors.brandPrimary,
                        borderWidth: '1px',

                        flex: '1',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        boxSizing: 'border-box',
                        // backgroundColor: cTheme.colors.background,
                        // boxShadow: '5px 5px 5px rgba(0, 0, 0, .2)',
                        boxShadow: 24,
                        cursor: 'pointer', // Ensure the cursor is a pointer to indicate it's clickable
                        userSelect: 'none', // Prevent text selection
                    }}>
                        <Typography variant='h4' gutterBottom
                                    onClick={() => scrollToRef(billRef)}>Bill</Typography>
                    </Paper>
                    <Paper sx={{
                        padding: 2,
                        margin: '20px 0',
                        borderRadius: 2,
                        borderStyle: 'solid',
                        background: cTheme.colors.brandSecondary,
                        color: cTheme.colors.brandPrimary,
                        borderWidth: '1px',

                        flex: '1',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        boxSizing: 'border-box',
                        // backgroundColor: cTheme.colors.background,
                        // boxShadow: '5px 5px 5px rgba(0, 0, 0, .2)',
                        boxShadow: 24,
                        cursor: 'pointer', // Ensure the cursor is a pointer to indicate it's clickable
                        userSelect: 'none', // Prevent text selection

                    }}>
                        <Typography variant='h4' gutterBottom
                                    onClick={() => scrollToRef(budgetRef)}>Budget</Typography>
                    </Paper>
                    <Paper sx={{
                        padding: 2,
                        margin: '20px 0',
                        borderRadius: 2,
                        borderStyle: 'solid',
                        background: cTheme.colors.brandSecondary,
                        color: cTheme.colors.brandPrimary,
                        borderWidth: '1px',

                        flex: '1',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        boxSizing: 'border-box',
                        // backgroundColor: cTheme.colors.background,
                        // boxShadow: '5px 5px 5px rgba(0, 0, 0, .2)',
                        boxShadow: 24,
                        cursor: 'pointer', // Ensure the cursor is a pointer to indicate it's clickable
                        userSelect: 'none', // Prevent text selection
                    }}>
                        <Typography variant='h4' gutterBottom
                                    onClick={() => scrollToRef(saveRef)}>Save</Typography>
                    </Paper>
                </Paper>
                <br/>
                <Box ref={billRef} sx={useStyles.cards}>
                    <Box padding='15px' paddingX='20px' sx={useStyles.cardVisual}>
                        <HeroShoppingPhoto/>
                    </Box>
                    <Box padding='15px' paddingX='20px' sx={{display: 'flex', ...useStyles.cardItem}}>
                        <Typography variant='body1' sx={bodyTextStyles}><span
                            style={{fontSize: '2rem', fontWeight: 'bold', color: cTheme.colors.brandSecondary}}>Billing </span>
                            your friends made easy with EZSplit. Divide a
                            bill with friends, family, or roommates. Whether you're out for dinner or
                            shopping for groceries, our platform allows you to split the cost based on
                            predefined rules. This means you can customize how each person contributes, making
                            it fair and stress-free. Perfect for those spontaneous outings where you need to
                            settle up quickly.</Typography>
                    </Box>
                </Box>
                <br/>
                <br/>
                <Box ref={budgetRef} sx={useStyles.cards}>
                    <Box padding='15px' paddingX='20px' sx={useStyles.cardVisual}>
                        <HeroUtilitiesPhoto/>
                    </Box>
                    <Box padding='15px' paddingX='20px' sx={{display: 'flex', ...useStyles.cardItem}}>
                        <Typography variant='body1' sx={bodyTextStyles}><span
                            style={{fontSize: '2rem', fontWeight: 'bold', color: cTheme.colors.brandSecondary}}>Budgeting </span>
                            with others has never been
                            easier. With EZSplit, you can plan for recurring expenses like rent, utilities, and
                            subscriptions. Set up your custom rules to determine how bills are divided, ensuring
                            transparency and fairness. Whether you're sharing costs with a partner, roommates,
                            or friends, our platform supports your unique financial arrangements, so everyone
                            knows exactly what they owe.</Typography>
                    </Box>
                </Box>
                <br/>
                <br/>
                <Box ref={saveRef} sx={useStyles.cards}>
                    <Box padding='15px' paddingX='20px' sx={useStyles.cardVisual}>
                        <HeroVacationPhoto/>
                    </Box>
                    <Box padding='15px' paddingX='20px' sx={{display: 'flex', ...useStyles.cardItem}}>
                        <Typography variant='body1' sx={bodyTextStyles}><span
                            style={{fontSize: '2rem', fontWeight: 'bold', color: cTheme.colors.brandSecondary}}>Saving </span>
                            for the future doesn't have
                            to be stressful. EZSplit lets you and your partner or friends save together towards
                            common goals. No more awkward conversations about contributions—set your custom
                            rules and track progress in one place. Whether it's for a vacation, a new home, or a
                            rainy day fund, our platform helps you achieve your savings goals collaboratively
                            and efficiently.</Typography>
                    </Box>
                </Box>
            </Paper>
        </Unauthorized>
    );
};

export default ViewLanding;
