const lightTheme = {
    current: "light",
    colors: {
        text: 'black',
        textAlt: 'white',
        textThemed: 'black',
        textAltThemed: 'white',
        background: '#F5F9FA',
        //background: '#FFFFFF',

        // brandSubmit: '#42E196',

        brandThemedPrimary: '#212447',
        brandThemedSecondary: '#68ADB7',
        brandPrimary: '#212447',
        brandSecondary: '#68ADB7',
        // brandPrimaryHover:'#21D480',
        brandSecondaryHover:'#00C8F5',

        brandTertiary: '#3DD4AD',
        brandTertiaryHover:'#2CC9A2',
        // Add more colors as needed
    },
    fonts: {
        primary: 'League Spartan',
    },
    spacing: {
        small: '8px',
        medium: '16px',
        large: '24px',
        // Add more spacing sizes as necessary
    },
    // Add any other lightTheme properties you need
};

export default lightTheme;


// TODO : much darker bg #00120B
// TODO : good dark theme background #061A40
// TODO : lighter option as a foreground? #1C2541
