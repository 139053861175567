import React, {useCallback, useContext, useEffect, useState} from 'react';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Unauthorized from "../Components/Layouts/Unauthorized";
import InputValidatorField from "../Components/Inputs/InputValidatorField";
import { IsValidEmail, IsValidPassword } from "../Utils/Validators";
import OTPInputField from "../Components/Inputs/OTPInputField";
import { AuthContext } from "../Providers/AuthProvider";
import { useHistory, useLocation } from "react-router-dom";
import { CsrfContext } from "../Providers/CsrfProvider";
import {sendResetPass, triggerResetPassToken} from "../api/Users";
import Typography from "@mui/material/Typography";

const ResetPassPage = () => {
    const [formData, setFormData] = useState({ verification_code: '', email: '', password: '' });
    const [showResetFields, setShowResetFields] = useState(false);
    const { authClaims } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const { csrfToken } = useContext(CsrfContext);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    useEffect(() => {
        const urlCode = new URLSearchParams(location.search).get('code');
        const urlEmail = new URLSearchParams(location.search).get('email');
        if (urlCode || urlEmail) {
            setFormData({
                verification_code: urlCode || '',
                email: decodeURIComponent(urlEmail) || '',
            });
        }
        if (urlEmail && urlCode) {
            setShowResetFields(true);
        } else if (urlEmail) {// Automatically show reset fields if URL contains email
            triggerForgotPasswordEmail()
        }
    }, [location.search]);

    const handleInputChange = (event) => {
        setFormData(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    };


    const triggerReset = () => {
        if (IsValidEmail(formData.email)) {
            triggerResetPassToken(formData.email, csrfToken)
                .then(() => {
                    setShowResetFields(true);
                })
                .catch(err => {
                    console.error('Unable to trigger reset password:', err);
                });
        } else {
            console.log("Invalid email address.");
        }
    };


    const triggerForgotPasswordEmail = useCallback(() => {
        if (!IsValidEmail(formData.email)) {
            console.log("Invalid email address.");
            return;
        }

        if (isButtonDisabled) {
            console.log("Wait for cooldown");
            return;
        }

        setIsButtonDisabled(true);

        triggerResetPassToken(formData.email, csrfToken)
            .then(() => {
                setShowResetFields(true);
            })
            .catch(err => {
                console.error('Unable to trigger reset password:', err);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 10000); // Enable the button after 10 seconds
            });
    }, [formData.email, csrfToken, isButtonDisabled]);

    const verifyCode = async () => {
        const { verification_code, email, password } = formData;
        if (verification_code !== undefined && verification_code.length === 6 && password !== undefined && password.length) {
            sendResetPass(email, password, verification_code, csrfToken)
                .then(response => {
                    // console.log("Result: ", response);
                    if (response.data.result === "" && response.data.error === "invalid verification code") {
                        console.log("Invalid verification code");
                    } else {
                        history.push(authClaims ? '/' : '/auth');
                    }
                })
                .catch(error => {
                    console.error('Error confirming email:', error);
                });
        }
    };


    return (
        <Unauthorized>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw'}}>
                <Box sx={{
                    position: 'relative',
                    width: 400,
                    height: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '30px'
                }}>
                    <br/>
                    <Typography variant="h6">Reset Password</Typography>
                    <br/>
                    <InputValidatorField label="Email" type="email" name="email" handleChange={handleInputChange}
                                         isValidInput={IsValidEmail} initialValue={formData.email}
                                         autoComplete="email"/>
                    <br/>
                    {!showResetFields && (<>
                        <Button variant="contained" color="primary" onClick={triggerReset}
                                disabled={!IsValidEmail(formData.email)} fullWidth sx={{mt: 2}}>
                            Reset Password
                        </Button><br/></>)}
                    {showResetFields && (<>
                            <Box sx={{display: 'flex', alignItems: 'center', width: '100%', mt: 2}}>
                                <Tooltip title="Check your email for a code">
                                    <OTPInputField
                                        verificationCode={formData.verification_code}
                                        handleChange={handleInputChange}
                                        name="verification_code"
                                        sx={{flexGrow: 1, mr: 1}}/></Tooltip>
                                {formData.verification_code.length < 6 && (
                                    <Tooltip title="Resend verification code">
                                        <IconButton onClick={triggerForgotPasswordEmail} size="small">
                                            <RefreshIcon/> </IconButton> </Tooltip>
                                )}
                            </Box>
                            <br/>
                            <InputValidatorField helperText="(Required, len >= 12)"
                                                 label="Password"
                                                 type="password"
                                                 name="password"
                                                 handleChange={handleInputChange}
                                                 isValidInput={IsValidPassword}
                                                 autoComplete="current-password"
                            />
                            <br/>
                            <Button type="submit" variant="contained" color="primary"
                                    disabled={formData.verification_code.length !== 6}
                                    fullWidth // || !formData.password.length
                                    sx={{mt: 2}} onClick={verifyCode}>
                                Confirm
                            </Button>
                            <br/>
                        </>
                    )}
                </Box>
            </Box>
        </Unauthorized>
    );
};

export default ResetPassPage;
