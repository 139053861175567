import axios from "axios";
import ApiClient from "./ApiClient";
import NewUUID from "../Utils/Randy/NewUUID";

export const listTemplateTransactions = (csrfToken) => {
    return async ({queryKey}) => {
        const [_, templateId] = queryKey
        const response = await ApiClient.get(`/api/bills/template/${templateId.toString()}/transactions`, {
            headers: {
                accept: "text/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        });
        return response.data.result
    }
}

export const addNewTransaction = (csrfToken) => {
    return async (formData) => {
        try {
            const response = await ApiClient.post(
                `/api/bills/template/${formData.templateId}/transactions`,
                {
                    amount: formData.amount,
                    statement_id: formData.statement_id,
                    user_sub: formData.user_sub,
                },
                {
                    headers: {
                        accept: 'application/json',
                        request_id: NewUUID(),
                        "X-CSRF-Token": csrfToken
                    },
                }
            );

            return response.data.result;
        } catch (error) {
            console.error('Error adding template transaction:', error);
            throw error; // Re-throw the error to propagate it to the mutation
        }
    };
};