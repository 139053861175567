import React, {useContext, useState} from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ThemeContext } from 'styled-components';
import {useTheme} from "../../Themes/ThemeProvider";
import IconNight from "../../Images/Icons/IconNight";
import IconDay from "../../Images/Icons/IconDay";
import {Brightness3, Brightness7} from "@mui/icons-material";
import {width} from "@mui/system";

const BtnDarkModeToggle = React.memo(() => {
    const { toggleTheme } = useTheme();
    const theme = useContext(ThemeContext);

    // dark mode enabled
    return (
        <ListItemButton
            onClick={toggleTheme}
            sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
            }}
        >
            <ListItemIcon
                sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: theme.colors.textAlt
                }}
            >
                {
                    theme.current === "light"
                        // ? <Brightness7/>
                        // : <Brightness3/>
                    ? <IconDay size={28}/>
                    : <IconNight size={28}/>
                }
            </ListItemIcon>
        </ListItemButton>
    )
});

export default BtnDarkModeToggle;