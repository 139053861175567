import React, {useContext} from 'react';
import Authorized from "../Components/Layouts/Authorized";
import {Box} from "@mui/system";
import {ThemeContext} from "styled-components";

const ViewDashboard = React.memo(() => {
    const theme = useContext(ThemeContext)
    return(
        <>
            <Authorized>
                <Box sx={{backgroundColor:theme.colors.brandPrimary, height:'100vh', width:'100vw'}}/>
            </Authorized>
        </>
    )
})

export default ViewDashboard;