// src/InputValidatorField.js
import React, {useContext} from "react";
import TextField from "@mui/material/TextField";
import { UseValidatorField } from "./Hooks/UseValidatorField";
import {InputAdornment, Tooltip} from "@mui/material";
import {ExclamationMark} from "@phosphor-icons/react";
import {ThemeContext} from "styled-components";

const InputValidatorField = ({
                                 handleChange,
                                 isValidInput,
                                 initialValue,
                                 InputLabelProps,
                                 InputProps,
                                 children,
                                 requirementsText,
                                 sx,
                                 ...props
                             }) => {
    const { error, onBlur, value, setValue } = UseValidatorField(isValidInput, initialValue);

    const handleInputChange = event => {
        const val = event.target.value;
        setValue(val);
        handleChange(event);
    };

    const OnBlur = e => {
        onBlur();
        handleChange(e);
    };

    const theme = useContext(ThemeContext)

    return (
        <>
            <TextField
                fullWidth
                error={error()}
                onBlur={OnBlur}
                onChange={handleInputChange}
                {...props}
                value={value}
                margin="normal"
                variant="outlined"
                size="small"
                InputLabelProps={{
                    ...InputLabelProps,
                    shrink: true,
                }}
                InputProps={{
                    ...InputProps,
                    startAdornment: (value.length < 1 || error()) && (requirementsText && requirementsText.length > 0) && (
                        <InputAdornment position='start'>
                            <Tooltip title= {requirementsText} arrow>
                            <ExclamationMark size={32} color={theme.colors.brandSecondary}/>
                        </Tooltip>
                    </InputAdornment>
                    ),
                }}
                FormHelperTextProps={{
                    sx: {
                        color: 'white', // Replace with the desired color for the helper text
                        fontSize:'.85rem'
                    },
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'white', // Set outline color to white
                        },
                        '&:hover fieldset': {
                            borderColor: 'white', // Set outline color to white on hover
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white', // Set outline color to white on focus
                        },
                    },
                    flex:1,
                    ...sx,
                }}
            />
            {children}
        </>
    );
};

export default InputValidatorField;
