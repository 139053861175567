import { createContext, useContext, useState } from 'react';

export const TemplateContext = createContext(null);

export const TemplateProvider = ({ children }) => {
    const [ownerSub, setOwnerSub] = useState('');

    return (
        <TemplateContext.Provider value={{ ownerSub, setOwnerSub }}>
            {children}
        </TemplateContext.Provider>
    );
};

export const useTemplateContext = () => useContext(TemplateContext);