import axios from "axios";
import ApiClient from "./ApiClient";
import NewUUID from "../Utils/Randy/NewUUID";

export const listTemplateParticipants = ( csrfToken) => {
    return async ({queryKey}) => {
        const [_, templateId] = queryKey
        const response = await ApiClient.get(`/api/bills/template/${templateId.toString()}/participants`, {
            headers: {
                accept: "text/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        });
        return response.data.result
    }
}

export const addTemplateParticipant = (csrfToken) => {
    return async (formData) => {
        try {
            const response = await ApiClient.put(
                `/api/bills/template/${formData.templateId}/participants`,
                {
                    email: formData.email,
                },
                {
                    headers: {
                        accept: 'application/json',
                        request_id: NewUUID(),
                        "X-CSRF-Token": csrfToken
                    },
                }
            );

            return response.data.result;
        } catch (error) {
            console.error('Error adding template participant:', error);
            throw error; // Re-throw the error to propagate it to the mutation
        }
    };
};


export const inviteTemplateParticipant = (csrfToken) => {
    return async (formData) => {
        try {
            const response = await ApiClient.post(
                `/api/notification/template/${formData.templateId}/invite`,
                {
                    email: formData.email,
                },
                {
                    headers: {
                        accept: 'application/json',
                        request_id: NewUUID(),
                        "X-CSRF-Token": csrfToken
                    },
                }
            );

            return response.data.result;
        } catch (error) {
            console.error('Error inviting template participant:', error);
            throw error; // Re-throw the error to propagate it to the mutation
        }
    };
};
