import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BtnDarkModeToggle from "./Buttons/BtnDarkModeToggle";
import {useContext} from "react";
import {AuthContext} from "../Providers/AuthProvider";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {GetIcon} from "../Utils/GetIcon/GetIcon";
import {ThemeContext} from "styled-components";
import {useQuery} from "react-query";
import {listUsersTemplates} from "../api/Templates";
import {Box} from "@mui/material";
import {CsrfContext, CsrfProvider} from "../Providers/CsrfProvider";
import {useHistory} from "react-router-dom";
import IconCollapseMenu from "../Images/Icons/IconCollapseMenu";
import IconExpandMenu from "../Images/Icons/IconExpandMenu";
import IconAddScheduled from "../Images/Icons/IconAddScheduled";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Sidebar = ({children}) => {
    const theme = useContext(ThemeContext);
    const [open, setOpen] = React.useState(false);
    const { authClaims } = useContext(AuthContext);
    const { csrfToken, } = useContext(CsrfContext);
    const history = useHistory();


    const { data, error, status } = useQuery("templates", () =>listUsersTemplates(csrfToken), {enabled: !!authClaims});

    const handleToggleDrawer = () => {
        setOpen(!open);
    };

    if (!authClaims) {
        return (<></>)
    }

    return (
            <Drawer variant="permanent" open={open} PaperProps={{
                sx: {
                backgroundColor: theme.colors.brandPrimary,
                    color: "white",
            }}}>
                <DrawerHeader/>
                <List>
                    <ListItem key={"toggle darkMode"} disablePadding sx={{ display: 'block' }}>
                        <BtnDarkModeToggle/>
                        <Divider sx={{backgroundColor: theme.colors.brandSecondary, boxShadow: "2"}}/>
                    </ListItem>
                    <ListItem key={"toggle drawer"} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={handleToggleDrawer}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: theme.colors.textAlt
                                }}
                            >
                                {open?<IconCollapseMenu size={28}/>:<IconExpandMenu size={28}/>}
                            </ListItemIcon>
                        </ListItemButton>
                        <Divider sx={{backgroundColor: theme.colors.brandSecondary, boxShadow: "2"}}/>
                    </ListItem>
                    <ListItem key={"Add recurring bill"} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={ ()=>history.push('/bills/templates') }
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color:theme.colors.textAlt
                                }}
                            >
                                <IconAddScheduled size={28}/>
                            </ListItemIcon>
                            <ListItemText primary={"Add recurring bill"} sx={{ opacity: open ? 1 : 0, color: theme.colors.textAlt}} />
                        </ListItemButton>
                        <Divider sx={{backgroundColor: theme.colors.brandSecondary, boxShadow: "2"}}/>
                    </ListItem>
                    {data?.map(({name, id}) => (
                        <ListItem key={id} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                onClick={ ()=>history.push(`/bills/templates/${id}`)}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                <GetIcon
                                    billTemplateName={name}
                                    style={{color:theme.colors.textAlt, fontSize:'28px'}}
                                />
                                </ListItemIcon>
                                <ListItemText primary={name} sx={{ opacity: open ? 1 : 0, color:theme.colors.textAlt }} />
                            </ListItemButton>
                            <Divider sx={{backgroundColor: theme.colors.brandSecondary, boxShadow: "2"}}/>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
    );
};

export default Sidebar;