import styled from 'styled-components';
import { Button } from '@mui/material';

const Btn = styled(Button)`
  && {
    background-color: ${(props) => props.theme.colors.brandTertiary};
    color: black;
    margin-top: 16px; // Assuming a margin-top is always desired
    width: ${(props) => props.fullWidth ? '100%' : 'initial'};
    &:hover {
      background-color: ${(props) => props.theme.colors.brandTertiaryHover};
    }
  }
`;

export default Btn;