import axios from "axios";
import ApiClient from "./ApiClient";
import NewUUID from "../Utils/Randy/NewUUID";

export const confirmEmail = (email, verification_code, csrfToken) => {
    return ApiClient.post(
        '/api/auth/confirm_email',
        {
            email: email,
            code: verification_code
        }, {
            headers: {
                accept: 'application/json',
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        }
    );
};

export const refreshAuthToken = (csrfToken) => {
    const resp = ApiClient.post('/api/auth/refresh', {
        headers: {
            accept: "application/json",
            request_id: NewUUID(),
            "X-CSRF-Token": csrfToken.toString()
        }
    })
    return resp.data.result
}

export const resendConfirmEmail = (email, csrfToken) => {
    return ApiClient.post(
        '/api/auth/resend_confirm_email',
        {
            email: email,
        }, {
            headers: {
                accept: 'application/json',
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        }
    );
};

export const sendResetPass = (email, password, verification_code, csrfToken) => {
    return ApiClient.post(
        '/api/auth/forgot_password/confirm',
        {
            email: email,
            new_password: password,
            confirmation_code: verification_code
        }, {
            headers: {
                accept: 'application/json',
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        }
    );
};

export const triggerResetPassToken = (email, csrfToken) => {
    return ApiClient.post(
        '/api/auth/forgot_password',
        {
            email: email,
        }, {
            headers: {
                accept: 'application/json',
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        }
    );
};