import {useContext, useState} from 'react'
import axios from "axios";
import {IsValidPassword, IsValidEmail, IsValidText} from "../../../Utils/Validators";
import ApiClient from "../../../api/ApiClient";
import {CsrfContext} from "../../../Providers/CsrfProvider";
import {useHistory, useLocation} from "react-router-dom";
import NewUUID from "../../../Utils/Randy/NewUUID";

export const UseSignupForm = () => {
    const {csrfToken} = useContext(CsrfContext)
    const history = useHistory();
    const location = useLocation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    });

    const handleInputChange = (e) => {
        // Update the formData state when form fields change
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmitForm = (event) => {
        event.preventDefault()
        if (process.env.ENVIRONMENT === 'prod') {
            alert("You're an early adopter! But hold tight, we are working out the kinks. Watch for an email in the next few weeks about how to move forward")
        }
        if (! allowSubmitForm()) {
            return
        }
        ApiClient.post('/api/auth/signup', {
            name:formData.name,
            email:formData.email,
            password:formData.password
        }, {
            headers: {
                accept: "application/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken.toString()
            }
        }).then(()=> {
            history.push('/verify?email='+encodeURIComponent(formData.email))
        }).catch((err)=> {
            console.error(err)
        })
    }

    const allowSubmitForm = () => {
        return IsValidEmail(formData.email) === true
            && IsValidPassword(formData.password) === true
            && IsValidText(formData.name) === true

    }

    return {
        handleInputChange,
        handleSubmitForm,
        allowSubmitForm,
    }
}
