const FormatDate = (dateString) => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);

    // Get the day as a number
    const day = date.getDate();

    // Get the month name
    const monthName = months[date.getMonth()];

    // Get the year
    const year = date.getFullYear();

    // Determine the ordinal suffix
    let ordinalSuffix;
    if (day % 10 === 1 && day !== 11) {
        ordinalSuffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
        ordinalSuffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
        ordinalSuffix = "rd";
    } else {
        ordinalSuffix = "th";
    }

    return `${monthName} ${day}${ordinalSuffix} ${year}`;
}

export default FormatDate;