import React, {useState, useEffect} from 'react';

export const AuthContext = React.createContext(null)

// Create a provider component
export const AuthProvider = ({ children }) => {
    const [authClaims, setAuthClaims] = useState(() => {
        // Retrieve saved authClaims from local storage
        const storedClaims = localStorage.getItem('authClaims');
        return storedClaims ? JSON.parse(storedClaims) : null;
    });

    const authContext = {
        authClaims: authClaims,
        setAuthClaims: setAuthClaims,
    };

    // Save authClaims to local storage
    useEffect(() => {
        localStorage.setItem('authClaims', JSON.stringify(authClaims));
    }, [authClaims]);

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
};
