import {useState} from "react";

const UseHybridForm = () => {
    const [canEdit, setCanEdit] = useState(false);

    const enableEditMode = () => {
        setCanEdit(true)
    }

    const enableViewMode = () => {
        setCanEdit(false)
    }

    const toggleMode = () => {
        setCanEdit(!canEdit)
    }

    return {
        canEdit,
        enableEditFormMode: enableEditMode,
        enableViewFormMode: enableViewMode,
        toggleFormMode: toggleMode,
    }
};

export default UseHybridForm;
