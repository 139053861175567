
const NewUUID = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export default NewUUID;

// function generateUUID() {
//     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//         var r = (crypto.getRandomValues(new Uint8Array(1))[0] % 16) | 0;
//         var v = c === 'x' ? r : (r & 0x3) | 0x8;
//         return v.toString(16);
//     });
// }