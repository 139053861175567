import React, {useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppMenu from './AppMenu'
import {BtnLogoBlkHori} from "./Buttons/Logo/BtnLogoBlkHori";
import {ThemeContext} from "styled-components";
import { AppBarContext } from '../App'; // Adjust the path as needed

const Header = React.memo(() => {
    const theme = useContext(ThemeContext);
    const appBarRef = useContext(AppBarContext);

    return (
        <>
        <AppBar ref={appBarRef} sx={{background: theme.colors.brandPrimary, zIndex: "1250"}} position="fixed">
            <Toolbar>
                <BtnLogoBlkHori/>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {/*TODO: this is required for header spacing*/}
                </Typography>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {/*TODO: this is required for header spacing*/}
                </Typography>
                <div
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <AppMenu/>
                </div>
            </Toolbar>
        </AppBar>
        </>
    );
});
export default Header;