import React, {useContext, useState} from 'react';
import {AuthContext} from "../../Providers/AuthProvider";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {addNewTransaction, listTemplateTransactions} from "../../api/Transactions";
import SlidingForm from "../Forms/SlidingForm";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {TimelineOppositeContent} from "@mui/lab";
import FormatDate from "../../Utils/Formatters/FormatDate";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Modal} from "@mui/base";
import {Box} from "@mui/system";
import { ThemeContext } from 'styled-components';
import {CsrfContext} from "../../Providers/CsrfProvider";
import {TemplateContext} from "../../Providers/TemplateProvider";
import TransactionModal from "../Modals/ModalTransactions";


const TransactionsModel = ({ templateId }) => {
    const theme = useContext(ThemeContext);
    const { authClaims } = useContext(AuthContext);
    const { csrfToken } = useContext(CsrfContext);
    const { ownerSub } = useContext(TemplateContext);

    const [modalOpen, setModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null); // State to hold the clicked item's details
    const [formData, setFormData] = useState({
        amount: 0,
        user_sub: "",
        statement_id: 0,
    });

    const handleOpenModal = (item) => {
        setCurrentItem(item); // Set the clicked item's details
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setCurrentItem(null); // Reset the currentItem when the modal is closed
    };


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: theme.colors.brandThemedPrimary,
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    const onChange = (e) => {
        const { name, value } = e.target;
        let parsedValue = value;
        // Check if the input type is number and convert the value
        if (e.target.type === 'number') {
            parsedValue = parseFloat(value);
            // Check if the parsedValue is NaN (Not a Number), then revert it to an empty string or zero
            if (isNaN(parsedValue)) {
                parsedValue = 0;
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: parsedValue,
        }));
    };

    // Mutation to manage data that updates
    const queryClient = useQueryClient();
    const mutation = useMutation(addNewTransaction(csrfToken), {
        onSuccess: () => {
            // Invalidate and refetch the 'users' query after adding a new user
            queryClient.invalidateQueries(['transactions', templateId]);
        },
    });

    const onSubmit = () => {
        if (formData.amount > 0) {
            formData.user_sub=currentItem.user.sub
            formData.statement_id=currentItem.statement_id
            handleAddTransaction(formData); // Combine existing and new users
            setFormData({ amount: 0}); // Reset the form data
        }
    };

    const handleAddTransaction = (formData) => {
        formData.templateId=templateId;
        mutation.mutate(formData);
    };

    // Query for initial data
    const { data: transactions, isLoading: isQueryLoading, isError: isQueryError } = useQuery(
        ['transactions', templateId],
        listTemplateTransactions(csrfToken)
    );

    if (isQueryLoading) {
        return <Typography sx={{color:theme.colors.textAlt}}>Loading...</Typography>;
    }

    if (isQueryError) {
        console.error('Error fetching statements:', isQueryError);
        return <Typography sx={{color:theme.colors.textAlt}}>Error fetching transactions, try refreshing</Typography>;
    }

    const isOwner = () => {
        return authClaims.user_sub.toUpperCase() === ownerSub.toUpperCase()
    }

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', width:'70%'}}>
                <Timeline>
                    {transactions && transactions.sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    }).map((item, index) => (
                        <TimelineItem key={`${item.created_at}+${item.user.email}`}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color={theme.colors.textAlt}
                            >
                                {/* Assuming FormatDate is a function you have for formatting dates */}
                                {FormatDate(item.created_at)}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color={item.amount > 0 ? "success" : "error"} />
                                {index < transactions.length - 1 ? <TimelineConnector /> : ""}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Button onClick={() => handleOpenModal(item)} sx={{ textTransform: 'none', color: 'black', padding: 0, '&:hover': { background: 'none' }, justifyContent: 'flex-start', width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Typography variant="h6" component="span" sx={{ textAlign: 'left', color:theme.colors.textAlt }}>
                                            {item.amount}
                                        </Typography>
                                        <Typography sx={{ textAlign: 'left',color:theme.colors.textAlt }}>
                                            {item.user.email}
                                        </Typography>
                                    </div>
                                </Button>
                            </TimelineContent>
                            {/* Modal for displaying the form */}
                            {currentItem && (
                                <TransactionModal
                                    open={modalOpen}
                                    handleClose={handleCloseModal}
                                    item={currentItem}
                                    isOwner={isOwner(currentItem.user.sub)}
                                />
                            )}
                        </TimelineItem>
                    ))}
                </Timeline>
            </div>
        </>
    );
};

export default TransactionsModel;