// src/MuiTheme.js
import { createTheme } from '@mui/material/styles';
import lightTheme from './LightTheme';
import darkTheme from './DarkTheme';

const muiLightTheme = createTheme({
    palette: {
        mode: 'light',
        background: {
            default: lightTheme.colors.background,
        },
        primary: {
            main: lightTheme.colors.brandPrimary,
        },
        secondary: {
            main: lightTheme.colors.brandSecondary,
        },
        text:{
            main: lightTheme.colors.textAlt,
            secondary: lightTheme.colors.text
        }
    },
    typography: {

        fontFamily: lightTheme.fonts.primary,
    },
});

const muiDarkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: darkTheme.colors.background,
        },
        primary: {
            main: darkTheme.colors.brandPrimary,
        },
        secondary: {
            main: darkTheme.colors.brandSecondary,
        },
        text:{
            main: lightTheme.colors.textAlt,
            secondary: lightTheme.colors.text
        }
    },
    typography: {
        fontFamily: darkTheme.fonts.primary,
    },
});

export { muiLightTheme, muiDarkTheme };
