import React, {useEffect, useRef, useState} from "react";
import './SlidingForm.css';
import Button from "@mui/material/Button"; // Make sure to create this CSS file

const SlidingForm = ({ onSubmit, onChange, name, placeholder, type, text, sx }) => {
    const [layer2Moved, setLayer2Moved] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const containerRef = useRef(null); // Step 1: Create a ref

    // Function to slide Layer 2 away, revealing Layer 1
    const toggleLayer = () => {
        setLayer2Moved(!layer2Moved);
    };

    // // Determine the background color based on state
    // const layer2Style = {
    //     backgroundColor: isHovered ? "#3069C5" : theme.col,
    // };


    const handleSubmit = () => {
        onSubmit();
        if (layer2Moved) {
            toggleLayer();
        }
    };

    // Step 2: Set up an event listener for clicks outside the component
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                if (layer2Moved) {
                    toggleLayer(); // Step 3: Toggle layer if click is outside
                }
            }
        };

        // Add event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerRef, toggleLayer]);

    return (
        <div className="container" ref={containerRef}> {/* Assign the ref to your container */}
            <div className="layer" id="layer1">
                <Button sx={{...sx}} type="submit" onClick={handleSubmit} className="spacer" variant="contained" color="primary">Submit</Button>
                <input step=".01" onChange={onChange} type={type} placeholder={placeholder} name={name} className="text-box"/>
            </div>
            <div
                className={`layer ${layer2Moved ? 'slide' : ''}`}
                id="layer2"
                onClick={toggleLayer}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{...sx}}
            >
                {text}
            </div>
        </div>
    );
}

export default SlidingForm;