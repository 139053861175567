import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { confirmEmail, resendConfirmEmail } from "../api/Users";
import { CsrfContext } from "../Providers/CsrfProvider";
import { AuthContext } from "../Providers/AuthProvider";
import Unauthorized from "../Components/Layouts/Unauthorized";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputValidatorField from "../Components/Inputs/InputValidatorField";
import { IsValidEmail } from "../Utils/Validators";
import Button from "@mui/material/Button";
import OTPInputField from "../Components/Inputs/OTPInputField";
import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ThemeContext } from "styled-components";

const VerifyEmail = () => {
    const [formData, setFormData] = useState({ verification_code: '', email: '' });
    const { authClaims } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const { csrfToken } = useContext(CsrfContext);
    const theme = useContext(ThemeContext);

    const getQueryParam = (param) => new URLSearchParams(location.search).get(param);

    const verifyCode = async () => {
        const { verification_code, email } = formData;
        if (verification_code.length === 6) {
            confirmEmail(email, verification_code, csrfToken)
                .then(response => {
                    if (response.data.result === "" && response.data.error === "invalid email verification code") {
                        // TODO - do something nicer here
                    } else {
                        if (!authClaims) {
                            history.push('/auth');
                        } else {
                            history.push('/');
                        }
                    }
                })
                .catch(error => {
                    console.error('Error confirming email:', error);
                });
        }
    };

    const autoVerifyCode = async (email, verification_code) => {
        if (verification_code.length === 6) {
            confirmEmail(email, verification_code, csrfToken)
                .then(result => {
                    if (!authClaims) {
                        history.push('/auth');
                    } else {
                        history.push('/');
                    }
                })
                .catch(error => {
                    console.error('Error confirming email:', error);
                });
        }
    };

    useEffect(() => {
        const urlCode = getQueryParam('code');
        const urlEmail = getQueryParam('email');
        if (urlCode || urlEmail) {
            setFormData({ verification_code: urlCode || '', email: decodeURIComponent(urlEmail) || '' });
        }
        if (urlCode && urlCode.length === 6 && urlEmail) {
            autoVerifyCode(urlEmail, urlCode);
        }
    }, [location.search]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmitConfirmForm = (e) => {
        e.preventDefault();
        verifyCode();
    };

    const handleResend = async () => {
        await resendConfirmEmail(formData.email, csrfToken);
    };

    return (
        <>
            <Unauthorized>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', backgroundColor: theme.colors.brandPrimary }}>
                    <Box component="form"
                         onSubmit={handleSubmitConfirmForm}
                         sx={{
                             position: 'relative',
                             width: 400,
                             bgcolor: theme.colors.brandPrimary,
                             boxShadow: 20,
                             borderRadius: 4,
                             display: 'flex',
                             flexDirection: 'column',
                             padding: '30px',
                             color: theme.colors.textAlt,
                             margin: 'auto'
                         }}>
                        <Typography variant="h6" sx={{color: theme.colors.textAlt}}>Verify Email</Typography>
                        <br/>
                        <InputValidatorField helperText="(Required)"
                                             label="Email"
                                             type="email"
                                             name="email"
                                             handleChange={handleInputChange}
                                             isValidInput={IsValidEmail}
                                             initialValue={formData.email}
                                             autoComplete="email"
                                             requirementsText="Valid email is required"
                                             InputLabelProps={{
                                                 style: {color: 'white'},
                                             }}
                                             InputProps={{
                                                 style: {color: 'white'},
                                             }}
                        />
                        <Box sx={{display: 'flex', alignItems: 'center', width: '100%', mt: 2}}>
                            <Tooltip title="Check your email for a code">
                                <OTPInputField
                                    verificationCode={formData.verification_code}
                                    handleChange={handleInputChange}
                                    name="verification_code"
                                    sx={{flexGrow: 1, mr: 1}}
                                />
                            </Tooltip>
                            {formData.verification_code.length < 6 && (
                                <Tooltip title="Resend verification code">
                                    <IconButton onClick={handleResend} size="small">
                                        <RefreshIcon sx={{color:theme.colors.textAlt}}/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        <br/>
                        <br/>
                        <Button type="submit" variant="contained" color="secondary" fullWidth
                                sx={{mt: 2, color: "primary", fontWeight: "bold", fontSize: "1.05em"}}>
                            CONFIRM </Button>
                    </Box>
                </Box>
            </Unauthorized>
        </>
    );
}

export default VerifyEmail;
