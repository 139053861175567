import axios from "axios";
import ApiClient from "./ApiClient";
import NewUUID from "../Utils/Randy/NewUUID";

export const listUsersTemplates = async (csrfToken) => {
    const response = await ApiClient.get('/api/bills/templates', {
        headers: {
            accept: "text/json",
            request_id: NewUUID(),
            "X-CSRF-Token": csrfToken
        },
    });
    return response.data.result
}

// export const getUsersTemplate = (authToken, csrfToken) => {
//     return async ({queryKey}) => {
//         const [_, id] = queryKey
//
//         const response = await ApiClient.get(`/api/bills/templates/${id.toString()}`, {
//             headers: {
//                 accept: "text/json",
//                 request_id: NewUUID(),
//                 "X-CSRF-Token": csrfToken
//             },
//         });
//         return response.data.result
//     }
// }
export const getUsersTemplate = async (id, csrfToken) => {
    const response = await ApiClient.get(`/api/bills/templates/${id}`, {
        headers: {
            accept: "application/json",  // It's typically "application/json", not "text/json"
            request_id: NewUUID(),
            "X-CSRF-Token": csrfToken
        },
    });
    // console.log(response)
    return response.data.result;
}

export const putUsersTemplate = (csrfToken) => {
    return async (formData) => {
        const response = await ApiClient.put(`/api/bills/templates/${formData.id}`, {
            name: formData.name,
            description: formData.description,
            typical_cost: parseInt(formData.typical_cost),
        }, {
            headers: {
                accept: "text/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken
            },
        });
        return response.data.result
    }
}