import React, {useContext, useState} from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import Unauthorized from "../Components/Layouts/Unauthorized";
import FormLogin from "../Components/Forms/FormLogin";
import FormSignup from "../Components/Forms/FormSignup";
import {ThemeContext} from "styled-components";

const ViewAuth = () => {
    const [value, setValue] = useState(0);
    const theme = useContext(ThemeContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Unauthorized>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                backgroundColor: theme.colors.brandPrimary
            }}>
                <Box sx={{
                    position: 'relative',
                    width: 400, // Adjust as needed
                    height: 600, // Adjust as needed
                    boxShadow: 20,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    color: theme.colors.textAlt
                }}>
                    <Tabs value={value} onChange={handleChange} aria-label="login signup tabs"
                          variant="fullWidth" // Makes tabs take up the full available width
                          indicatorColor="secondary" // Color of the tab indicator
                          textColor="inherit" // Color of the text in the tabs
                          sx={{
                              '.MuiTabs-flexContainer': {
                                  justifyContent: 'space-between', // Spreads out the tabs
                              },
                              '.MuiTab-root': {
                                  maxWidth: 'none',  // Allows tabs to take more space
                                  flex: 1,  // Each tab flexes to fill the space
                                  padding: '6px 12px', // Padding around text in each tab, adjust as needed
                                  margin: '0 8px', // Margin on the sides of each tab, adjust as needed
                                  '&.Mui-selected': {
                                      fontSize: '15px', // Font size when selected
                                      fontWeight: 'bold' // Optional: make text bold when selected
                                  }
                              }
                          }}
                    >
                        <Tab label="Login" />
                        <Tab label="Join" />
                    </Tabs>
                    <Box sx={{ display:'flex', flexGrow: 1, p: 3 }}>
                        {value === 0 ? <FormLogin /> : <FormSignup />}
                    </Box>
                </Box>
            </Box>
        </Unauthorized>
    );
};

export default ViewAuth;
