import React, {Component, useContext} from 'react';
import axios from 'axios';
import {FormLogin} from "../Components/Forms/FormLogin";
import Cookies from 'universal-cookie';
import {AuthContext} from "../Providers/AuthProvider";
import {useHistory} from "react-router-dom";


export const LogoutPage = () => {
    const history = useHistory();
    // Remove auth token from session
    const { authClaims, setAuthClaims } = useContext(AuthContext);
    setAuthClaims(null)

    // Remove auth token from cookie
    const cookies = new Cookies()
    cookies.remove("claims")

    // Redirect to login page
    history.push('/auth')
}
