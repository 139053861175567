import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import menuButton from "../Images/menuButton.svg";
import {useContext} from "react";

import { AuthContext } from '../Providers/AuthProvider';
import {Link} from "react-router-dom";
import IconAppMenu from "../Images/Icons/IconAppMenu";
import {ThemeContext} from "styled-components";
import {DotsThreeOutline} from "@phosphor-icons/react";

const AppMenu = React.memo(() => {
    const cTheme = useContext(ThemeContext)
    const { authClaims, setAuthClaims } = useContext(AuthContext);

    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                    <Button variant="unstyled" {...bindTrigger(popupState)}>
                        <DotsThreeOutline size={32} color={cTheme.colors.textAlt}/>
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        { ! authClaims
                            ? [<MenuItem key="1" onClick={popupState.close}><Link to="/auth">Login</Link></MenuItem>,
                                <MenuItem key="2" onClick={popupState.close}><Link to="/auth?type=signup">Join</Link></MenuItem>]
                            : [<MenuItem key="1" onClick={popupState.close}><Link to="/logout">Logout</Link></MenuItem>,
                                <MenuItem key="2" onClick={popupState.close}><Link to="/profile">Profile</Link></MenuItem>
                            ]
                        }
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
});

export default AppMenu;