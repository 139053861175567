import React, {useContext} from 'react';
import { useQuery } from 'react-query';
import { Modal, Box, Typography, Button } from '@mui/material';
import SlidingForm from "../Forms/SlidingForm";
import ApiClient from "../../api/ApiClient";
import {CsrfContext} from "../../Providers/CsrfProvider";
import NewUUID from "../../Utils/Randy/NewUUID";

const fetchPaymentOptions = async (csrfToken, templateId) => {
    const { data } = await ApiClient.get(`/api/banking/templates/${templateId}`, {
        headers: {
            accept: "text/json",
            request_id: NewUUID(),
            "X-CSRF-Token": csrfToken
        }
    });
    return data.response;
};


const TransactionModal = ({ open, handleClose, item, isOwner}) => {
    const {csrfToken}=useContext(CsrfContext)
    // console.log("template info: ", item)
    const { data: paymentOptions, isLoading } = useQuery(
        ['template_payment_options', item.bill_id],
        () => fetchPaymentOptions(csrfToken, item.bill_id),
        {
            enabled: open && !isOwner // Only fetch when modal is open and user is not the owner
        }
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Manage Bill
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    User {item.user.email} currently owes {item.amount}
                </Typography>
                {isOwner ? (
                    <SlidingForm name="amount" placeholder="amount" type="number" text="Cash Payment" />
                ) : (
                    isLoading ? <p>Loading payment options...</p> : (
                        paymentOptions && Array.isArray(paymentOptions) ? paymentOptions.map(paymentOption => (
                            paymentOption.provider.toLowerCase() === "venmo"?
                            <Button target="_blank" href={`https://venmo.com/?txn=pay&recipients=${paymentOption.venmo_id.replace(/^@/, '')}&amount=${encodeURIComponent(Math.abs(item.amount))}&note=${encodeURIComponent("ezsplit")}`} variant="contained" color="primary">
                                Pay with {paymentOption.provider}
                            </Button>
                                :
                            <Button variant="contained" color="primary">
                                Pay with {paymentOption.provider}
                            </Button>
                        )) : <Typography>Ask the bill owner to configure payment methods</Typography>
                    )
                )}
                <Button onClick={handleClose}>Close</Button>
            </Box>
        </Modal>
    );
};

export default TransactionModal;
