import {useContext, useState} from 'react'
import axios from "axios";
import {AuthContext} from "../../../Providers/AuthProvider";
import ApiClient from "../../../api/ApiClient";
import {CsrfContext} from "../../../Providers/CsrfProvider";
import {useHistory} from "react-router-dom";
import {IsValidVenmoUsername} from "../../../Utils/Validators"
import NewUUID from "../../../Utils/Randy/NewUUID";
import {linkAccount} from "../../../api/Banking";
import {useQueryClient} from "react-query";
//
// export const UseFormLinkVenmo = () => {
//     const history = useHistory();
//     const {authClaims} = useContext(AuthContext)
//     const {csrfToken} = useContext(CsrfContext)
//     const [formData, setFormData] = useState({
//         venmoId: '',
//         confirmVenmoId: '',
//     });
//
//     const handleInputChange = (e) => {
//         // Update the formData state when form fields change
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value,
//         });
//     };
//
//     const handleSubmitForm = (event) => {
//     //     event.preventDefault()
//     //     if (! allowSubmitForm()) {
//     //         return
//     //     }
//     //
//     //     if (! id) {
//     //         ApiClient.post('/api/bills/templates', {
//     //             name: formData.name,
//     //             description: formData.description,
//     //             typical_cost: parseInt(formData.typical_cost)
//     //         }, {
//     //             headers: {
//     //                 accept: "application/json",
//     //                 "Content-Type": "application/json",
//     //                 request_id: NewUUID(),
//     //                 "X-CSRF-Token": csrfToken
//     //             },
//     //         }).then(response => {
//     //             id = response.data.result.id
//     //             history.push(`/bills/templates/${response.data.result.id}`)
//     //         }).catch((err) => {
//     //             console.error(err)
//     //         })
//     //     } else {
//     //         ApiClient.put(`/api/bills/templates/${id}`, {
//     //             name: formData.name,
//     //             description: formData.description,
//     //             typical_cost: parseInt(formData.typical_cost)
//     //         }, {
//     //             headers: {
//     //                 accept: "application/json",
//     //                 "Content-Type": "application/json",
//     //                 request_id: NewUUID(),
//     //                 "X-CSRF-Token": csrfToken
//     //             },
//     //         }).then(response => {
//     //             id = response.data.result.id
//     //             history.push(`/bills/templates/${response.data.result.id}`)
//     //         }).catch((err) => {
//     //             console.error(err)
//     //         })
//     //     }
//     }
//
//     const allowSubmitForm = () => {
//         return formData.venmoId === formData.confirmVenmoId && IsValidVenmoUsername(formData.venmoId)
//     }
//
//     return {
//         handleInputChange,
//         handleSubmitForm,
//         allowSubmitForm,
//     }
// }
//


export const UseFormLinkVenmo = (initialData) => {
    const { authClaims } = useContext(AuthContext);
    const { csrfToken } = useContext(CsrfContext);
    const [formData, setFormData] = useState(initialData);
    const history = useHistory();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmitForm = (event) => {
        event.preventDefault();
        if (!allowSubmitForm()) {
            return;
        }
        linkAccount(csrfToken, formData.venmoId, "Venmo").then(()=>{
            // console.log("linked venmo")
        })
        // Submit logic here
    }

    const allowSubmitForm = () => {
        return formData.venmoId === formData.confirmVenmoId && IsValidVenmoUsername(formData.venmoId);
    }

    return {
        formData,
        handleInputChange,
        handleSubmitForm,
        allowSubmitForm,
    }
}
export default UseFormLinkVenmo
