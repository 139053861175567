import { useContext } from 'react';
import { AuthContext } from '../../../Providers/AuthProvider';
import { CsrfContext } from "../../../Providers/CsrfProvider";

const useLogout = () => {
    const { csrfToken } = useContext(CsrfContext);
    const { setAuthClaims } = useContext(AuthContext);

    return (history) => {
        // Implement your logout logic here
        // Clear session information
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem('user');
        sessionStorage.removeItem('sessionData');

        // Update application state if using Redux or Context API
        // dispatch(logoutUser()); or setAuthState({ isAuthenticated: false, user: null });

        setAuthClaims(null);

        // Redirect to the login page
        // const params = new URLSearchParams();
        // // params.set('returnUrl', encodeURIComponent(history.location.pathname + history.location.search));
        // params.set('returnUrl', history.location.pathname + history.location.search)
        //
        // history.push(`/auth?${params.toString()}`);
    };
};

export default useLogout;
