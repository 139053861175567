// src/api/axiosConfig.js
import axios from 'axios';
import {authEvents} from "../EventHandlers/AuthEventHandler";
import NewUUID from "../Utils/Randy/NewUUID";


const ApiClient = axios.create({
    baseURL:process.env.REACT_APP_API_URL,
    headers: {
        'Accept': 'application/json', // Ensure this aligns with what your API expects
        session_id: NewUUID(),
        // Any other default headers
    },
    withCredentials: true
});

ApiClient.interceptors.response.use(response => response, error => {
    if (error.response && error.response.data === 'token is invalid') {
        // Logic to handle token refresh or prompt for re-login
        authEvents.onTokenInvalid()
        // For example, you could broadcast an event or use a context method to update auth state
    }
    return Promise.reject(error);
});

export default ApiClient;