import isEmail from "validator/lib/isEmail";

export const IsValidPassword = (val) =>  {
    const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_])/;
    return val!==undefined && val?.length >= 12 && val.length <= 36 && passwordRegex.test(val)
}

export const IsValidText = (val) => {
    return val!==undefined && val?.length >= 1
}

export const IsValidNumber = (val) => {
    return val!==undefined && val?.length >= 1 && !isNaN(parseFloat(val)) && isFinite(val);
}

export const IsValidEmail = (val) => {
    return val!==undefined && val?.length >= 1 && isEmail(val)
}

export const IsValidVenmoUsername = (username) => {
    const pattern = /^@[a-zA-Z0-9_]{5,16}$/;
    return pattern.test(username);
};

