import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import VenmoUsernameInput from "../../Inputs/VenmoUsername";
import useFormLinkVenmo, {UseFormLinkVenmo} from "./UseFormLinkVenmo";
//
// const FormLinkVenmo = ({ open, handleClose }) => {
//     const {
//         handleInputChange,
//         handleSubmitForm,
//         allowSubmitForm,
//     } = useFormLinkVenmo()
//
//     const [venmoId, setVenmoId] = useState('');
//     const [confirmVenmoId, setConfirmVenmoId] = useState('');
//
//     const handleVenmoIdChange = (e) => {
//         setVenmoId(e.target.value);
//     };
//
//     const handleConfirmVenmoIdChange = (e) => {
//         setConfirmVenmoId(e.target.value);
//     };
//
//     const idsMatch = venmoId && confirmVenmoId && venmoId === confirmVenmoId;
//
//     return (
//         <Modal open={open} onClose={handleClose}>
//             <Box sx={{
//                 position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
//                 width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2
//             }}>
//                 <Typography variant="h6" component="h2">Link Venmo Account</Typography>
//                 <Typography sx={{ mt: 2 }}>Enter your Venmo ID:</Typography>
//                 <VenmoUsernameInput
//                     label="Venmo ID"
//                     value={venmoId}
//                     onChange={handleVenmoIdChange}
//                     onBlur={handleVenmoIdChange}
//                 />
//                 {venmoId && (
//                     <>
//                         <Typography sx={{ mt: 2 }}>Confirm your Venmo ID:</Typography>
//                         <VenmoUsernameInput
//                             label="Confirm Venmo ID"
//                             value={confirmVenmoId}
//                             onChange={handleConfirmVenmoIdChange}
//                             onBlur={handleConfirmVenmoIdChange}
//                         />
//                     </>
//                 )}
//                 <Button
//                     fullWidth
//                     variant="contained"
//                     disabled={!idsMatch}
//                     onClick={() => console.log("Venmo ID linked:", venmoId)}
//                     sx={{ mt: 2 }}
//                 >
//                     Link Venmo ID
//                 </Button>
//             </Box>
//         </Modal>
//     );
// };
//



const FormLinkVenmo = ({ open, handleClose }) => {
    const initialData = { venmoId: '', confirmVenmoId: '' };
    const {
        formData,
        handleInputChange,
        handleSubmitForm,
        allowSubmitForm,
    } = UseFormLinkVenmo(initialData);

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2 }}>
                <Typography variant="h6" component="h2">Link Venmo Account</Typography>
                <TextField
                    label="Venmo ID"
                    value={formData.venmoId}
                    onChange={handleInputChange}
                    name="venmoId"
                    margin="normal"
                    fullWidth
                />
                {formData.venmoId && (
                    <>
                        <Typography sx={{ mt: 2 }}>Confirm your Venmo ID:</Typography>
                        <TextField
                            label="Confirm Venmo ID"
                            value={formData.confirmVenmoId}
                            onChange={handleInputChange}
                            name="confirmVenmoId"
                            margin="normal"
                            fullWidth
                        />
                    </>
                )}
                <Button
                    fullWidth
                    variant="contained"
                    disabled={!allowSubmitForm()}
                    onClick={handleSubmitForm}
                    sx={{ mt: 2 }}
                >
                    Link Venmo ID
                </Button>
            </Box>
        </Modal>
    );
};
export default FormLinkVenmo
