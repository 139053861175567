import React, {Component, useContext} from 'react';
import axios from 'axios';
import './Statement.css'
import ApiClient from "../../api/ApiClient";
import {CsrfContext} from "../../Providers/CsrfProvider";

class Statements extends Component {
    _isMounted = false;

    constructor(){
        super();
        this.state = {
            groups: [],
        };
        this.getStatements = this.getStatements.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.getStatements();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

//Retrieves all the items from the logged in user's cart
    getStatements(){
        ApiClient.get('/api/groups/list/'+1).then((req)=> {
            this.setState({groups:req.data.results[0]})
        })
    }

    render(){
        if (this.state.groups.length < 1 ){
            return []
        }
        let groupdata = [];
        for (var groupid in this.state.groups) {
            let usersnames = [];
            let usersids = [];
            let usersusernames = [];
            let _group = this.state.groups[groupid];
            _group.map((_user, i)=>{
                usersids.push(
                    <div key={_user.id + i}>
                        {_user.id}
                    </div>
                );
                usersnames.push(
                    <div key={_user.name + i}>
                        {_user.name}
                    </div>
                );
                usersusernames.push(
                    <div key={_user.username + i}>
                        {_user.username}
                    </div>
                );
            });
            groupdata.push(
                <div key={groupid} className="userflex">
                    <div className="usersidcolumn" >
                        <h3>id:</h3><br/>
                        {usersids}
                    </div>
                    <div className="usersnamecolumn" >
                        <h3>name:</h3><br/>
                        {usersnames}
                    </div>
                    <div className="usersusernamecolumn" >
                        <h3>username:</h3><br/>
                        {usersusernames}
                    </div>
                </div>
            )
        }

        return (
            <div>
                {groupdata}
            </div>
        )

    }
}
export default Statements;

