import React, {useContext} from 'react'
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {UseTemplateForm} from "./Hooks/UseTemplateForm";
import InputValidatorField from "../Inputs/InputValidatorField";
import {IsValidEmail, IsValidNumber, IsValidText} from "../../Utils/Validators";
import Btn from "../Buttons/Styled/Button";
import {ThemeContext} from "styled-components";

const FormTemplate = React.memo(() => {
    const {
        handleInputChange,
        handleSubmitForm,
        allowSubmitForm,
    } = UseTemplateForm();

    const theme = useContext(ThemeContext);

    return (
        <>
            <Box sx={{backgroundColor:theme.colors.brandPrimary, height:'100vh', width:'100vw'}}>
            <Box
                component="form"
                onSubmit={handleSubmitForm}
                sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    // maxWidth: '500px',
                    margin: 'auto',
                    padding: '20px',
                    boxShadow: 20,
                    borderRadius: 4,
                    // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    backgroundColor: theme.colors.brandPrimary,
                    color:theme.colors.textAlt
                }}
            >
                <Typography variant="h5" component="div" sx={{mb: 2}}>
                   Bill Template
                </Typography>
                <br/>
                <InputValidatorField helperText="(Required)"
                                     label="Name"
                                     name="name"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidText}
                                     requirementsText="Required - provide a description to help identify this"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                /> <br/>
                <InputValidatorField helperText="(Required)"
                                     label="Description"
                                     name="description"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidText}
                                     requirementsText="Required - provide a description to help identify this"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                /> <br/>
                <InputValidatorField helperText="(Required)"
                                     label="Typical Cost"
                                     name="typical_cost"
                                     type="number"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidNumber}
                                     requirementsText="Required - help us estimate your costs"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                /> <br/>
                <Button type="submit" variant="contained" disabled={!allowSubmitForm()} color="secondary" fullWidth
                        sx={{mt: 2, color: "primary", fontWeight: "bold", fontSize: "1.05em"}}>
                    Create </Button>

                <br/>
            </Box>
            </Box>
        </>
    );
});
export default FormTemplate;