import React, { useContext } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {ThemeContext} from "styled-components";
import {AuthContext} from "../Providers/AuthProvider";
import {useQuery} from "react-query";
import {listUsersTemplates} from "../api/Templates";
import {GetIcon} from "../Utils/GetIcon/GetIcon";
import {CsrfContext} from "../Providers/CsrfProvider";
import {useHistory} from "react-router-dom";
import IconAddScheduled from "../Images/Icons/IconAddScheduled"; // Ensure you have this import for the icon
// Import other necessary icons and ThemeContext

const Footer = ({ children }) => {
    const history = useHistory();
    const theme = useContext(ThemeContext);
    const { authClaims, setAuthClaims } = useContext(AuthContext);
    const {csrfToken} = useContext(CsrfContext)
    const { data, error, status } = useQuery("templates", () =>listUsersTemplates(csrfToken), {enabled: !!authClaims});

    const footerStyle = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw', // Ensure full viewport width
        margin: 0,
        padding: 0,
        backgroundColor: theme.colors.brandPrimary,
    };

    const listStyle = {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap', // Prevents items from wrapping
        '-ms-overflow-style': 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
        '&::-webkit-scrollbar': {
            display: 'none', /* Chrome, Safari, and Opera */
        },
        padding: '0px',
        margin: '0px',
        background: theme.colors.brandPrimary,
    };

    const listItemStyle = {
        display: 'inline-flex', // Ensure items are in a line
        marginRight: '20px', // Space between items
    };

    if (!authClaims) {
        return (<>
            <Box sx={footerStyle}/>
        </>)
    }


    return (
        <>
        <Box sx={footerStyle}>
            <List sx={listStyle}>
                {/* Example list item, adjust according to your needs */}
                <ListItem disablePadding sx={listItemStyle}>
                    <ListItemButton onClick={() => history.push('/bills/templates')}>
                        <ListItemIcon  sx={{ color: theme.colors.textAlt }}>
                            <IconAddScheduled size={28} />
                        </ListItemIcon>
                        <ListItemText primary={"Add recurring bill"} sx={{ color: theme.colors.textAlt }} />
                    </ListItemButton>
                </ListItem>
                {data?.map(({name, id}) => (
                    <ListItem key={id} disablePadding sx={listItemStyle}>
                        <ListItemButton
                            onClick={ ()=>history.push(`/bills/templates/${id}`)}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <GetIcon
                                    billTemplateName={name}
                                    style={{color:theme.colors.textAlt, fontSize:'28px'}}
                                />
                            </ListItemIcon>
                            <ListItemText primary={name} sx={{ opacity: open ? 1 : 0, color:theme.colors.textAlt }} />
                        </ListItemButton>
                        <Divider orientation="vertical" sx={{backgroundColor: theme.colors.brandSecondary, boxShadow: "2"}}/>
                    </ListItem>
                ))}
            </List>
        </Box>
        </>
    );
};

export default Footer;