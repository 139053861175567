import { useEffect, useContext } from 'react';
import {AuthContext} from "../Providers/AuthProvider";
import {useHistory} from "react-router-dom";

export const AuthEventHandler = () => {
    const history = useHistory();
    const { setAuthClaims } = useContext(AuthContext);

    useEffect(() => {
        const handleTokenInvalid = () => {
            setAuthClaims(null); // Invalidate token
            history.push("/auth")
        };

        authEvents.listenToTokenInvalid(handleTokenInvalid);

        return () => {
            authEvents.removeTokenInvalidListener(handleTokenInvalid);
        };
    }, [setAuthClaims]);

    return null; // This component doesn't render anything
};

export const authEvents = {
    onTokenInvalid: () => {
        const event = new Event('onTokenInvalid');
        window.dispatchEvent(event);
    },
    listenToTokenInvalid: (handler) => {
        window.addEventListener('onTokenInvalid', handler);
    },
    removeTokenInvalidListener: (handler) => {
        window.removeEventListener('onTokenInvalid', handler);
    }
};
