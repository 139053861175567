import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from "../../Providers/AuthProvider";
import { ThemeContext } from 'styled-components';
import { CsrfContext } from "../../Providers/CsrfProvider";
import Button from "@mui/material/Button";
import {getLinkToken, linkAccount, listLinkedAccounts} from "../../api/Banking";
import {useMutation, useQuery, useQueryClient} from "react-query";
import FormLinkVenmo from "../Forms/Accounts/FormLinkVenmo";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";

const LinkedAccountsModel = () => {
    const theme = useContext(ThemeContext);
    const { authClaims } = useContext(AuthContext);
    const { csrfToken } = useContext(CsrfContext);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [linkToken, setLinkToken] = useState(null);
    const queryClient = useQueryClient();
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const [showVenmoModal, setShowVenmoModal] = useState(false);


    // Plaid Link setup
    const plaidLink = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false); // Close dropdown if click is outside
            }
        };

        // Add when the dropdown is shown and clean up on hide or unmount
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]); // Ensure effect runs if showDropdown changes


    const AccountLinkOptions = () => {
        return (
            <div ref={dropdownRef} style={{
                position: 'absolute',
                top: '100%',  // Position the dropdown right below the button
                width: '100%',
                backgroundColor: 'white',
                boxShadow: '0 4px 8px rgba(0,0,0,0.25)',
                borderRadius: '4px',
                padding: '10px',
                marginTop: '5px', // slight offset from the button
                zIndex: 1000, // ensure it's above other content
            }}>
                { (authClaims && authClaims.groups && authClaims.groups.includes("BetaAccess"))?
                <Button onClick={() => handleAddPlaidAccount()} fullWidth variant="contained" color="secondary" sx={{colors:theme.colors.brandPrimary}}>
                    Link Plaid Account
                </Button>
                :<></>}
                <Button onClick={() => setShowVenmoModal(true)} fullWidth variant="contained" color="secondary" sx={{colors:theme.colors.brandPrimary}} style={{ marginTop: '10px' }}>
                    Link Venmo Account
                </Button>
            </div>
        );
    };


    useEffect(() => {
        if (authClaims && authClaims.groups && authClaims.groups.includes("BetaAccess")) {
            // Load the Plaid script
            const script = document.createElement('script');
            script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
            script.async = true;
            script.onload = () => setScriptLoaded(true);
            script.onerror = () => console.error('Failed to load the Plaid script.');
            document.body.appendChild(script);

            // Fetch the link token
            const fetchToken = async () => {
                try {
                    const token = await getLinkToken(csrfToken);
                    setLinkToken(token);
                } catch (error) {
                    console.error('Failed to fetch the link token:', error);
                }
            };

            fetchToken();

            return () => {
                document.body.removeChild(script);
            };
        }
    }, [csrfToken]);  // Re-run the effect if authToken or csrfToken changes

    useEffect(() => {
        if (scriptLoaded && linkToken) {
            initializePlaidLink(linkToken);
        }
    }, [scriptLoaded, linkToken]);  // Only run this effect when scriptLoaded or linkToken changes

    const { data: accounts, isLoading: isQueryLoading, isError: isQueryError } = useQuery(
        ['accounts'],
        listLinkedAccounts(csrfToken)
    );

    const initializePlaidLink = (linkToken) => {
        if (!linkToken) return;

        const plaid = window.Plaid;
        if (plaid) {
            plaidLink.current = plaid.create({
                token: linkToken,
                onSuccess: async (public_token, metadata) => {
                    // console.log('Plaid onSuccess:', public_token, metadata);
                    try {
                        await linkAccount(csrfToken, public_token, "Plaid");
                        await queryClient.invalidateQueries(['accounts']);
                    } catch (error) {
                        // console.error('Error exchanging public token:', error);
                    }
                },
                onExit: (err, metadata) => {
                    // console.error('Plaid onExit:', err, metadata);
                }
            });
        }
    };

    const handleAddPlaidAccount = async () => {
        try {
            if (!!linkToken) {
                initializePlaidLink(linkToken)

                // Assuming plaidLink.current holds the Plaid instance and should be opened only after receiving the token.
                if (plaidLink.current) {
                    plaidLink.current.open();
                }
            }
        } catch (error) {
            console.error('Failed to get link token:', error);
            // Handle errors (e.g., display an error message)
        }
    };


    return (
        <>
            <div style={{ position: 'relative', width: '100%', marginTop: '20px' }}>
                {!isQueryLoading && !isQueryError && accounts ? (
                    <ul style={{ padding: 0 }}>
                        {accounts.map((account, index) => (
                            <li key={index} style={{ listStyleType: 'none', marginBottom: '10px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ flex: 1, textAlign: 'center',color:theme.colors.textAlt}}>{account.name}</Typography>
                                    <Typography sx={{ flex: 1, textAlign: 'center',color:theme.colors.textAlt }}>{account.subtype}</Typography>
                                    <Typography sx={{ flex: 1, textAlign: 'center',color:theme.colors.textAlt }}>{account.mask}</Typography>
                                </Box>
                            </li>
                        ))}
                    </ul>
                ) : <Typography sx={{color:theme.colors.textAlt}}>Loading...</Typography>}
                <Button type="button" fullWidth variant="contained" color="secondary"sx={{color:theme.colors.brandPrimary}}
                        onClick={() => setShowDropdown(prev => !prev)}>
                    Add Account
                </Button>
                {showDropdown && <AccountLinkOptions />}
            </div>
            <FormLinkVenmo open={showVenmoModal} handleClose={() => setShowVenmoModal(false)} />
        </>
    );
};

export default LinkedAccountsModel;