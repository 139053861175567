import {useContext, useState} from 'react'
import axios from "axios";
import { AuthContext } from '../../../Providers/AuthProvider';
import {useHistory} from "react-router-dom";
import ApiClient from "../../../api/ApiClient";
import {IsValidEmail, IsValidPassword} from "../../../Utils/Validators";
import NewUUID from "../../../Utils/Randy/NewUUID";
import {CsrfContext} from "../../../Providers/CsrfProvider";

export const UseLoginForm = () => {
    const { setAuthClaims } = useContext(AuthContext);
    const { csrfToken } = useContext(CsrfContext)
    const history = useHistory();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        SubmittedLogin: false,
        IsEmailVerified: false,
    });

    const handleLoginSubmit = (isEmailVerified, areCredentialsInvalid) => {
        setFormData((currInfo)=>({
            ...currInfo,
            ["AreCredentialsInvalid"]: areCredentialsInvalid,
            ["IsEmailVerified"]: isEmailVerified,
            ["SubmittedLogin"]: true,
        }));
    }

    const handleInputChange = (e) => {
        // Update the formData state when form fields change
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // const cookies = new Cookies();

    const handleSubmitForm = (event) => {
        event.preventDefault();
        if (!allowSubmitForm()) {
            return;
        }

        ApiClient.post('/api/auth/login', {
            email: formData.email,
            password: formData.password
        }, {
            headers: {
                accept: "application/json",
                request_id: NewUUID(),
                "X-CSRF-Token": csrfToken.toString()
            }
        }).then((response) => {
            if (response.data.error) {
                if (response.data.error === "invalid username or password") {
                    handleLoginSubmit(true, true);
                } else if (response.data.error === "user is not confirmed") {
                    history.push(`/verify?email=${encodeURIComponent(formData.email)}`);
                    handleLoginSubmit(false, false);
                }
            } else {
                handleLoginSubmit(true);
                // const expirationTime = new Date(new Date().getTime() + (1000*180));// 30 minutes
                // cookies.set('Claims', JSON.stringify(response.data.result), { expires: expirationTime });
                setAuthClaims(response.data.claims); // Store claims
                history.push('/');
            }
        }).catch((err)=> {
            console.error(err)
        })
    }

    const allowSubmitForm = () => {
        return (IsValidEmail(formData.email) && IsValidPassword(formData.password))
    }

    const enableVerifyEmailNotification = () => {
        return !formData.IsEmailVerified && formData.SubmittedLogin
    }

    const enableInvalidCredentialsNotification = () => {
        return formData.AreCredentialsInvalid && formData.SubmittedLogin
    }

    return {
        handleSubmitForm,
        handleInputChange,
        allowSubmitForm,
        enableVerifyEmailNotification,
        enableInvalidCredentialsNotification,
    }
}
