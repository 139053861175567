import React, {useContext, useEffect, useRef, useState} from 'react';
import './App.css';
import {CsrfContext, CsrfProvider, useCsrf} from "./Providers/CsrfProvider";
import {LogoutPage} from './Views/LogoutPage'
import Statements from './Views/Statement/Statements'
import ViewLanding from './Views/ViewLanding';
import ViewDashboard from "./Views/ViewDashboard";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import ViewNewTemplate from "./Views/ViewNewTemplate";
import ViewTemplates from "./Views/ViewTemplates";
import {ThemeProvider} from "./Themes/ThemeProvider";
import { createContext } from 'react';
import {AuthProvider, AuthContext} from "./Providers/AuthProvider";
import {AuthEventHandler} from "./EventHandlers/AuthEventHandler";
import VerifyEmail from "./Views/ConfirmEmail";
import MyProfileView from "./Views/MyProfileView";
import AuthPage from "./Views/ViewAuth";
import ResetPassPage from "./Views/ResetPassPage";
import InactivityForm from "./Components/Forms/InactivityForm";
import {Redirect, Route, BrowserRouter as Router} from "react-router-dom";

export const AppBarContext = createContext();

const App = () => {
    return (
        <div className="App">
            <CsrfProvider>
            <AuthProvider>
                <AuthEventHandler/>
                <ThemeProvider>
                    <CssBaseline />
                    {/*<Box component="main" sx={{flexGrow: 1, p: 3, height:'100vh', width:'100vw'}}>*/}
                        <Routes/>
                    {/*</Box>*/}
                </ThemeProvider>
            </AuthProvider>
            </CsrfProvider>
        </div>
    );
}

export const Routes = () => {
    const {authClaims} = useContext(AuthContext);

    return (
        <Router>
            <InactivityForm>
                {/*<PublicRoute path='/login' component={LoginPage}/>*/}
                <PublicRoute path='/verify' component={VerifyEmail}/>
                <PublicRoute path='/profile' component={MyProfileView}/>
                {/* TODO : view other's profiles */}
                {/*<PublicRoute path='/profile/:id' component={ProfileView}/>*/}
                <PublicRoute path='/auth' component={AuthPage}/>
                <PublicRoute path='/forgot' component={ResetPassPage}/>
                <PublicRoute path='/logout' component={LogoutPage}/>
                {/*<PublicRoute path='/signup' component={SignupPage}/>*/}
                <PrivateRoute path='/bills/templates/:id' component={ViewTemplates}/>
                <PrivateRoute exact path='/bills/templates' component={ViewNewTemplate}/>
                <PrivateRoute path='/statements' component={Statements}/>
                <PublicRoute exact path='/' component={authClaims ? ViewDashboard : ViewLanding}/>
            </InactivityForm>
        </Router>
    )
}

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { authClaims } = useContext(AuthContext);

    return (
        <Route {...rest} render={props => {
            if (!authClaims) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/auth' }} />
            }

            // authorized so return component
            return <Component {...props} />
        }} />
    );
}

export const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            // public route so return component
            return <Component {...props} />
        }} />
    );
}

export default App;





export const AppBarProvider = ({ children }) => {
    const appBarRef = useRef(null);
    const [appBarHeight, setAppBarHeight] = useState(0);

    useEffect(() => {
        if (appBarRef.current) {
            setAppBarHeight(appBarRef.current.clientHeight);
        }
    }, []);

    return (
        <AppBarContext.Provider value={{ appBarRef, appBarHeight }}>
            {children}
        </AppBarContext.Provider>
    );
};