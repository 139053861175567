import { useEffect } from 'react';

const useInactivityTimer = (onTimeout, onLogout, authClaims, timeout = 600000, logoutTime = 300000) => {
    let activityTimer;
    let logoutTimer;

    const resetTimer = () => {
        clearTimeout(activityTimer);
        clearTimeout(logoutTimer);
        if (authClaims) { // Only set timers if authClaims is present
            activityTimer = setTimeout(() => {
                onTimeout();
                logoutTimer = setTimeout(onLogout, logoutTime);
            }, timeout);
        }
    };

    useEffect(() => {
        const handleActivity = () => {
            resetTimer();
        };

        if (authClaims) { // Only listen to events if authClaims is present
            document.addEventListener('mousemove', handleActivity);
            document.addEventListener('keypress', handleActivity);
            document.addEventListener('click', handleActivity);
            document.addEventListener('scroll', handleActivity);

            resetTimer(); // Initialize the timer when the component mounts
        }

        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keypress', handleActivity);
            document.removeEventListener('click', handleActivity);
            document.removeEventListener('scroll', handleActivity);
            clearTimeout(activityTimer);
            clearTimeout(logoutTimer);
        };
    }, [authClaims]); // React to changes in authClaims

    return { resetTimer };
};

export default useInactivityTimer;
