// CsrfProvider.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import ApiClient from "../api/ApiClient";

export const CsrfContext = createContext("");

export const useCsrf = () => useContext(CsrfContext);

export const CsrfProvider = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        const getCsrfToken = async () => {
            const token = await fetchCsrfToken(); // Adjust this based on your API call
            setCsrfToken(token);
        };

        getCsrfToken();
    }, []);

    const csrfContext = {
        csrfToken: csrfToken, setCsrfToken: setCsrfToken,
    };

    return (
        <CsrfContext.Provider value={csrfContext}>
            {children}
        </CsrfContext.Provider>
    );
};

export const fetchCsrfToken = async () => {
    try {
        // Using ApiClient to fetch the CSRF token
        const response = await ApiClient.get('/api/csrf', { withCredentials: true });
        // Assuming the response structure has the CSRF token directly in 'data'
        return response.data.csrfToken;
    } catch (error) {
        console.error('Error fetching CSRF token:', error);
        return ''; // Return an empty string or handle the error as needed
    }
};