import React from "react";
import LogoDrkHori from "../../../Images/Logo/LogoDrkHori";

export const BtnLogoBlkHori = () => {
    return (
        <a href="/">
            <LogoDrkHori />
        </a>
    )
}
