import React, { useState, useRef, useContext } from "react";
import { ThemeContext } from "styled-components";

const OTPInputField = ({ verificationCode, handleChange, name, sx }) => {
    const inputsRef = useRef([]);
    const [hover, setHover] = useState(false);
    const theme = useContext(ThemeContext);

    const handleInputChange = (index, event) => {
        const value = event.target.value;

        if (value === "" || /^[0-9]$/.test(value)) {
            const fakeEvent = {
                target: {
                    name: name,
                    value: verificationCode.substring(0, index) + value + verificationCode.substring(index + 1)
                }
            };

            handleChange(fakeEvent);

            if (value !== "" && index < 5) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace") {
            const updatedValue = verificationCode.substring(0, index) + '' + verificationCode.substring(index + 1);
            const fakeEvent = {
                target: {
                    name: name,
                    value: updatedValue
                }
            };
            handleChange(fakeEvent);

            if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData('text').slice(0, 6); // Get the clipboard data and slice to 6 characters
        const newVerificationCode = verificationCode.split('');

        // Assign pasted data to the verification code based on current focus
        let selectedIndex = inputsRef.current.findIndex(input => input === document.activeElement);
        pasteData.split('').forEach((char, index) => {
            const position = selectedIndex + index;
            if (position < 6) {
                newVerificationCode[position] = char;
                if (position < 5) {
                    inputsRef.current[position + 1].focus();
                }
            }
        });

        const fakeEvent = {
            target: {
                name: name,
                value: newVerificationCode.join('')
            }
        };
        handleChange(fakeEvent);
    };

    return (
        <div
            style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
        >
            {Array.from({ length: 6 }).map((_, index) => (
                <input
                    key={index}
                    type="tel"
                    autoComplete="one-time-code"
                    inputMode="numeric"
                    maxLength="1"
                    value={verificationCode[index] || ""}
                    onChange={e => handleInputChange(index, e)}
                    onKeyDown={e => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                    ref={el => inputsRef.current[index] = el}
                    style={{
                        width: 'calc((100% - 60px) / 6)',
                        height: '40px',
                        textAlign: 'center',
                        marginRight: index < 5 ? '10px' : '0px',
                        backgroundColor: theme.colors.brandPrimary,
                        border: `1px solid ${hover ? theme.colors.textAlt : theme.colors.textAlt}`,
                        borderRadius: '4px',
                        color: theme.colors.textAlt,
                    }}
                />
            ))}
        </div>
    );
};

export default OTPInputField;
