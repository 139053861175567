import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';
import {QueryClient, QueryClientProvider} from "react-query";

const domNode = document.getElementById('root');
const root = createRoot(domNode);

const queryClient = new QueryClient();

root.render(
    <>
        {/*<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"/>*/}
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </>
);
