const NewString = (length) => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~!@#$%^&*()-+={}[]|\\:;"\'<>,.?/_';
    let result = '';
    const charactersLength = characters.length;
    const cryptoObj = window.crypto || window.msCrypto; // For IE 11 support

    for (let i = 0; i < length; i++) {
        const randomValue = cryptoObj.getRandomValues(new Uint32Array(1))[0];
        result += characters.charAt(randomValue % charactersLength);
    }

    return result;
}

export default NewString;