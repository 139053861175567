import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Paper, Tabs, Tab, Typography } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { TemplateProvider } from '../Providers/TemplateProvider';
import { ThemeContext } from 'styled-components';
import { throttle } from 'lodash';
import Authorized from '../Components/Layouts/Authorized';
import TemplateModel from '../Components/Models/TemplateModel';
import ParticipantsModel from '../Components/Models/ParticipantsModel';
import StatementsModel from '../Components/Models/StatementsModel';
import TransactionsModel from '../Components/Models/TransactionsModel';

const ViewTemplates = () => {
    const mediaTheme = useTheme();
    const theme = useContext(ThemeContext);
    const isMediumOrLarger = useMediaQuery(mediaTheme.breakpoints.up('md'));
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const scrollContainerRef = useRef(null);
    const sectionRefs = useRef([]);

    const sectionTitles = ["Configuration", "Participants", "Statements", "Transactions"];
    let dividerBackground = theme.colors.brandSecondary;

    // Function to scroll to a section based on its index
    const scrollToSection = (index) => {
        const section = sectionRefs.current[index];
        if (section && scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const topPos = section.offsetTop - container.offsetTop;
            container.scrollTo({
                top: topPos,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        const handleScroll = throttle(() => {
            const scrollContainer = scrollContainerRef.current;
            const containerScrollPosition = scrollContainer.scrollTop;

            sectionRefs.current.forEach((section, index) => {
                if (!section) return;
                const sectionTop = section.offsetTop - scrollContainer.offsetTop;
                const sectionBottom = sectionTop + section.offsetHeight;

                if (sectionTop <= containerScrollPosition && sectionBottom > containerScrollPosition) {
                    setActiveTab(index);
                }
            });
        }, 200);

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const assignRef = (section, index) => {
        sectionRefs.current[index] = section;
    };

    return (
        <>
            <Authorized>
                <TemplateProvider>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                            width: '100vw',
                            position: 'fixed',
                            overflow: 'hidden',
                            backgroundColor: theme.colors.brandPrimary,
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '800px',
                                margin: 'auto',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                height: '85vh',
                                width: '90vw',
                                position: 'fixed',
                                bottom: '0',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                boxShadow: 20,
                                borderRadius: 4,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Tabs
                                value={activeTab}
                                onChange={(e, newValue) => { setActiveTab(newValue); scrollToSection(newValue); }}
                                variant="scrollable"
                                scrollButtons="auto"
                                // variant="fullWidth" // Makes tabs take up the full available width
                                indicatorColor="secondary" // Color of the tab indicator
                                textColor="inherit" // Color of the text in the tabs
                                sx={{
                                    '.MuiTabs-flexContainer': {
                                        justifyContent: 'space-between', // Spreads out the tabs
                                    },
                                    '.MuiTab-root': {
                                        maxWidth: 'none',  // Allows tabs to take more space
                                        flex: 1,  // Each tab flexes to fill the space
                                        padding: '6px 12px', // Padding around text in each tab, adjust as needed
                                        margin: '0 8px', // Margin on the sides of each tab, adjust as needed
                                        '&.Mui-selected': {
                                            fontSize: '15px', // Font size when selected
                                            fontWeight: 'bold' // Optional: make text bold when selected
                                        }
                                    }
                                }}
                            >
                                {sectionTitles.map((title, index) => (
                                    <Tab sx={{ color:theme.colors.textAlt}} key={index} label={title} />
                                ))}
                            </Tabs>
                            <div ref={scrollContainerRef} style={{ overflow: 'auto', height: 'calc(100% - 48px)' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div
                                            key="section-configuration"
                                            ref={(el) => assignRef(el, 0)}
                                            data-title={"Configuration"}
                                            style={{
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                            }}>
                                            <Paper sx={{
                                                padding: 2,
                                                margin: '20px 0',
                                                borderRadius: 2,
                                                borderColor: theme.colors.brandSecondary,
                                                borderStyle: 'solid',
                                                background: theme.colors.brandPrimary,
                                                color: theme.colors.brandSecondary,
                                                borderWidth: '2',
                                            }}>
                                                <Typography variant="h6" sx={{
                                                    fontWeight: "bold"
                                                }}>Configuration</Typography>
                                            </Paper>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <TemplateModel id={id} />
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <div
                                            key="section-participants"
                                            ref={(el) => assignRef(el, 1)}
                                            data-title={"Participants"}
                                            style={{
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                            }}>
                                            <Paper sx={{
                                                padding: 2,
                                                margin: '20px 0',
                                                borderRadius: 2,
                                                borderColor: theme.colors.brandSecondary,
                                                borderStyle: 'solid',
                                                background: theme.colors.brandPrimary,
                                                color: theme.colors.brandSecondary,
                                                borderWidth: '2',
                                            }}>
                                                <Typography variant="h6" sx={{
                                                    fontWeight: "bold"
                                                }}>Participants</Typography>
                                            </Paper>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <ParticipantsModel templateId={id} />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <br />
                                <br />
                                <div
                                    key="section-statements"
                                    ref={(el) => assignRef(el, 2)}
                                    data-title={"Statements"}
                                    style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}>
                                    <Paper sx={{
                                        padding: 2,
                                        margin: '20px 0',
                                        borderRadius: 2,
                                        borderColor: theme.colors.brandSecondary,
                                        borderStyle: 'solid',
                                        background: theme.colors.brandPrimary,
                                        color: theme.colors.brandSecondary,
                                        borderWidth: '2',
                                    }}>
                                        <Typography variant="h6" sx={{
                                            fontWeight: "bold"
                                        }}>Statements</Typography>
                                    </Paper>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <StatementsModel templateId={id} />
                                    </div>
                                </div>
                                <div
                                    key="section-transactions"
                                    ref={(el) => assignRef(el, 3)}
                                    data-title={"Transactions"}
                                    style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                    }}>
                                    <Paper sx={{
                                        padding: 2,
                                        margin: '20px 0',
                                        borderRadius: 2,
                                        borderColor: theme.colors.brandSecondary,
                                        borderStyle: 'solid',
                                        background: theme.colors.brandPrimary,
                                        color: theme.colors.brandSecondary,
                                        borderWidth: '2',
                                    }}>
                                        <Typography variant="h6" sx={{
                                            fontWeight: "bold"
                                        }}>Transactions</Typography>
                                    </Paper>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <TransactionsModel templateId={id} />
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Box>
                </TemplateProvider>
            </Authorized>
        </>
    );
};

export default ViewTemplates;
