import {useEffect, useState} from "react";

export const UseValidatorField = (isValidInput, initialValue) => {
    const [dirty, setDirty] = useState(false);
    const [value, setValue] = useState(initialValue === undefined ? '' : initialValue);

    useEffect(() => {
        if (initialValue !== undefined) {
            setValue(initialValue);
        }
    }, [initialValue]);  // Dependency on initialValue

    const onBlur = () => {
        setDirty(true)
    }

    const error = () => {
        return dirty && value !== undefined && !isValidInput(value)
    }

    return {
        error,
        onBlur,
        value,
        setValue
    }
};
