import React from "react";

const TbLetterA = React.lazy(() => import("./LetterAIcon.js"))
const TbLetterB = React.lazy(() => import("./LetterBIcon.js"))
const TbLetterC = React.lazy(() => import("./LetterCIcon.js"))
const TbLetterD = React.lazy(() => import("./LetterDIcon.js"))
const TbLetterE = React.lazy(() => import("./LetterEIcon.js"))
const TbLetterF = React.lazy(() => import("./LetterFIcon.js"))
const TbLetterG = React.lazy(() => import("./LetterGIcon.js"))
const TbLetterH = React.lazy(() => import("./LetterHIcon.js"))
const TbLetterI = React.lazy(() => import("./LetterIIcon.js"))
const TbLetterJ = React.lazy(() => import("./LetterJIcon.js"))
const TbLetterK = React.lazy(() => import("./LetterKIcon.js"))
const TbLetterL = React.lazy(() => import("./LetterLIcon.js"))
const TbLetterM = React.lazy(() => import("./LetterMIcon.js"))
const TbLetterN = React.lazy(() => import("./LetterNIcon.js"))
const TbLetterO = React.lazy(() => import("./LetterOIcon.js"))
const TbLetterP = React.lazy(() => import("./LetterPIcon.js"))
const TbLetterQ = React.lazy(() => import("./LetterQIcon.js"))
const TbLetterR = React.lazy(() => import("./LetterRIcon.js"))
const TbLetterS = React.lazy(() => import("./LetterSIcon.js"))
const TbLetterT = React.lazy(() => import("./LetterTIcon.js"))
const TbLetterU = React.lazy(() => import("./LetterUIcon.js"))
const TbLetterV = React.lazy(() => import("./LetterVIcon.js"))
const TbLetterW = React.lazy(() => import("./LetterWIcon.js"))
const TbLetterX = React.lazy(() => import("./LetterXIcon.js"))
const TbLetterY = React.lazy(() => import("./LetterYIcon.js"))
const TbLetterZ = React.lazy(() => import("./LetterZIcon.js"))


export const GetIcon = ({billTemplateName, ...props}) =>  {
    let v = billTemplateName.toLowerCase()
    switch (v[0]) {
        case 'a': return (<TbLetterA {...props}/>)
        case 'b': return (<TbLetterB {...props}/>)
        case 'c': return (<TbLetterC {...props}/>)
        case 'd': return (<TbLetterD {...props}/>)
        case 'e': return (<TbLetterE {...props}/>)
        case 'f': return (<TbLetterF {...props}/>)
        case 'g': return (<TbLetterG {...props}/>)
        case 'h': return (<TbLetterH {...props}/>)
        case 'i': return (<TbLetterI {...props}/>)
        case 'j': return (<TbLetterJ {...props}/>)
        case 'k': return (<TbLetterK {...props}/>)
        case 'l': return (<TbLetterL {...props}/>)
        case 'm': return (<TbLetterM {...props}/>)
        case 'n': return (<TbLetterN {...props}/>)
        case 'o': return (<TbLetterO {...props}/>)
        case 'p': return (<TbLetterP {...props}/>)
        case 'q': return (<TbLetterQ {...props}/>)
        case 'r': return (<TbLetterR {...props}/>)
        case 's': return (<TbLetterS {...props}/>)
        case 't': return (<TbLetterT {...props}/>)
        case 'u': return (<TbLetterU {...props}/>)
        case 'v': return (<TbLetterV {...props}/>)
        case 'w': return (<TbLetterW {...props}/>)
        case 'x': return (<TbLetterX {...props}/>)
        case 'y': return (<TbLetterY {...props}/>)
        case 'z': return (<TbLetterZ {...props}/>)
        default: return (<></>)

    }
}