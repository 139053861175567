import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useLogout from "../Modals/Engines/UseLogout";
import useInactivityTimer from "../Modals/Engines/UseInactivityTimer";
import Logout from "../Modals/ModalLogoutButton";
import { AuthContext } from "../../Providers/AuthProvider";

const InactivityForm = ({ children }) => {
    const history = useHistory();
    const logout = useLogout();
    const { authClaims } = useContext(AuthContext);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [forcedLogout, setForcedLogout] = React.useState(false);

    const handleTimeout = () => {
        setModalVisible(true);
    };

    const handleStayLoggedIn = () => {
        setModalVisible(false);
        setForcedLogout(false);
        resetTimer();
    };

    const handleLogout = () => {
        setModalVisible(false)
        setForcedLogout(true);
    };

    const { resetTimer } = useInactivityTimer(handleTimeout, () => {
        handleLogout();
        logout(history);
    }, authClaims);

    return (
        <>
            {modalVisible && (
                <Logout
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    forcedLogout={forcedLogout}
                    logout={() => logout(history)}
                    stayLoggedIn={handleStayLoggedIn}
                />
            )}
            {children}
        </>
    );
};

export default InactivityForm;
