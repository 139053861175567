import React, {useContext} from 'react'
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {UseLoginForm} from "./Hooks/UseLoginForm";
import InputValidatorField from "../Inputs/InputValidatorField";
import {IsValidEmail, IsValidPassword} from "../../Utils/Validators";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Tooltip} from "react-tooltip";
import {InputAdornment} from "@mui/material";
import {ExclamationMark} from "@phosphor-icons/react";
import {ThemeContext} from "styled-components";



const ErrorMessage = ({ children }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#d22727', // Error color
            background: '#faeeef', // Light background color for the error message
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)', // Subtle shadow
            mt: 2, // Margin top for spacing
        }}
    >
        <WarningAmberIcon sx={{ marginRight: '8px' }} /> {/* Error Icon */}
        <Typography>{children}</Typography>
    </Box>
);

const FormLogin = React.memo(() => {
    const {
        handleSubmitForm,
        handleInputChange,
        allowSubmitForm,
        enableVerifyEmailNotification,
        enableInvalidCredentialsNotification,
    } = UseLoginForm();
    const theme = useContext(ThemeContext)

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmitForm}
                sx={{
                    // position: 'absolute',
                    // left: '50%',
                    // top: '50%',
                    // transform: 'translate(-50%, -50%)',
                    // maxWidth: '500px',
                    margin: 'auto',
                    padding: '20px',
                    borderRadius: '8px',
                    height: '100',
                    flex: 1
                    // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                }}
            >
                <InputValidatorField helperText="(Required)"
                                     label="Email"
                                     type="email"
                                     name="email"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidEmail}
                                     autoComplete="email"
                                     requirementsText="Valid email is required"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                />
                <br/>
                <br/>
                <InputValidatorField helperText="(Required)"
                                     label="Password"
                                     type="password"
                                     name="password"
                                     handleChange={handleInputChange}
                                     isValidInput={IsValidPassword}
                                     requirementsText={["",
                                                 <div>
                                                     Password must include:
                                                     <br/>* 12 - 36 Characters
                                                     <br/>* Uppercase & Lowercase letters
                                                     <br/>* Numbers
                                                     <br/>* One special character: ^$*.[]{}()?-"!@#%&/\,':;|_~`+=
                                                 </div>
                                         , ""]}
                                     autoComplete="current-password"
                                     InputLabelProps={{
                                         style: {color: 'white'},
                                     }}
                                     InputProps={{
                                         style: {color: 'white'},
                                     }}
                />
                <br/>
                <br/>
                <br/>
                <Button type="submit" variant="contained" color="secondary" fullWidth
                        sx={{mt: 2, color: "primary", fontWeight: "bold", fontSize: "1.05em"}}>
                    Login </Button>
                <br/>
                <Button
                    href={"/forgot"}
                    type="button"
                    variant="outlined"
                    fullWidth
                    sx={{
                        mt: 2, alignSelf: 'center',
                        borderColor: 'white',
                        color: 'white',
                        '&:hover': {
                            borderColor: 'lightgray', // Optional: Change the outline color on hover
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional: Change the background color on hover
                        },
                    }}
                >
                    Forgot
                </Button>
                <br/>
                {enableVerifyEmailNotification() && (
                    <ErrorMessage>Verify your email first <br/> an email is on its way</ErrorMessage>
                )}
                {enableInvalidCredentialsNotification() && (
                    <ErrorMessage>
                        Invalid login credentials <br/> confirm your email and password
                    </ErrorMessage>
                )}
            </Box>
        </>
    );
});
export default FormLogin;