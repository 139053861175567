import { useQuery, useMutation } from 'react-query';
import { useContext, useState } from 'react';
import {getUsersTemplate, putUsersTemplate} from "../../../api/Templates";
import {AuthContext} from "../../../Providers/AuthProvider";
import {CsrfContext} from "../../../Providers/CsrfProvider";
import {TemplateContext} from "../../../Providers/TemplateProvider";

const UseTemplateModel = (id) => {
    const { authClaims } = useContext(AuthContext);
    const { csrfToken } = useContext(CsrfContext);
    const { ownerSub,setOwnerSub } = useContext(TemplateContext);
    const [formData, setFormData] = useState({ name: 'loading...', description: '', typical_cost: 0, owner_sub: '' });

    // const { isLoading, isError } = useQuery(['templates', id], () => getUsersTemplate(authToken, csrfToken), {
    //     onSuccess: data => setFormData(data)
    // });
    const { isLoading, isError } = useQuery(['templates', id], () => getUsersTemplate(id, csrfToken), {
        onSuccess: data => {
            // console.log(data)
            setFormData(data)
            setOwnerSub(data.owner_sub)
        }
    });

    // Mutation for submitting form data
    const mutation = useMutation(['templates',id], putUsersTemplate(csrfToken), {
        // Specify the mutation queryKey
        queryKey: ['templates', id],
    });

    const handleSubmit = (data) => {
        mutation.mutate(data);
    };

    return {
        formData,
        setFormData,
        isLoading,
        isError,
        handleSubmit,
        mutation
    };
};
export default UseTemplateModel;