import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import { useMediaQuery, useTheme } from '@mui/material';
import Sidebar from "../Sidebar";

const Unauthorized = ({children}) => {
    const theme = useTheme();
    const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));

    return(
        <>
            <Header/>
            {children}
            {isMediumOrLarger?
                <Sidebar/>
                :
                <Footer/>
            }
        </>
    )
};

export default Unauthorized;